import {AuthToken} from "../config/axios";
import {apiDelete, apiGet, apiPost} from "./apiUtils";
import {CreateInvoiceDto, InvoiceDto} from "../dto/invoice.dto";
import {NextInvoiceNumberDto} from "../dto/NextInvoiceNumber.dto";

export const getInvoices = async (token: AuthToken) =>
  await apiGet<InvoiceDto[]>(`invoice`, token)

export const getInvoice = async (token: AuthToken, id: string) =>
  await apiGet<InvoiceDto>(`invoice/${id}`, token)

export const sendInvoiceToCustomer = async (token: AuthToken, id: string) =>
  await apiPost<void>(`invoice/${id}/send`, token, null)

export const deleteInvoice = async (token: AuthToken, id: string) =>
  await apiDelete<void>(`invoice/${id}`, token)

export const getInvoiceDownloadUrl = async (token: AuthToken, id: string) =>
  await apiGet<string>(`invoice/download/${id}`, token)

export const getInvoiceStillDue = async (token: AuthToken) =>
  await apiGet<InvoiceDto[]>(`invoice/unpaid`, token)

export const createInvoice = async (token: AuthToken, dto: CreateInvoiceDto) =>
  await apiPost<InvoiceDto>(`invoice`, token, dto)

export const invoiceUpdatePaymentStatus = async (token: AuthToken, id: string) =>
  await apiPost<InvoiceDto>(`invoice/${id}/update-payment-status`, token, null)

export const invoicesForClient = async (token: AuthToken, clientId: string) =>
  await apiGet<InvoiceDto[]>(`invoice/client/${clientId}`, token)

export const renderInvoicePreview = async (token: AuthToken, dto: CreateInvoiceDto) =>
  await apiPost<Blob>(`invoice/preview`, token, dto, {
    responseType: 'blob',
    headers: {
      Accept: "application/pdf",
    }
  })

export const getNextInvoiceNumber = async (token: AuthToken) =>
  await apiGet<NextInvoiceNumberDto>(`invoice/next-number`, token)

export const downloadInvoice = async (token: AuthToken, id: string) =>{
  const url = await getInvoiceDownloadUrl(token, id)
  console.log("Download url", url.data)
  const downloadAnchor = document.createElement('a');
  downloadAnchor.href = url.data;
  downloadAnchor.download = 'file.pdf';
  downloadAnchor.target = "_BLANK"
  
  // Append the anchor element to the document and click it to start the download
  document.body.appendChild(downloadAnchor);
  downloadAnchor.click();
  document.body.removeChild(downloadAnchor);
}