import {FormSelectField} from "../utils/BasicFormField";
import {Controller, useFormContext} from "react-hook-form";
import Grid2 from "@mui/material/Unstable_Grid2";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

export default function FormFieldDropdownComponent(props: {
  field: FormSelectField<any>,
  grid: number,
  name?: string,
  style?: string,

}) {
  const methods = useFormContext<any>()
  const fieldName: string = props.name !== undefined ? props.name : props.field.name as string
  const error = methods.getFieldState(fieldName, methods.formState).error
  return <>
    <Controller
      name={fieldName}
      control={methods.control}
      defaultValue={''}
      render={({field, fieldState: {error}}) => (
        <Grid2 md={props.grid}>
          <FormControl fullWidth>
            <InputLabel htmlFor={"client-" + fieldName}>{props.field.label}</InputLabel>
            <Select
              variant={props.style !== undefined ? (props.style as any) : "outlined"}
              {...field}
              labelId={"client-" + fieldName}
              label={props.field.label}
              onChange={event => field.onChange(event)}>
              {props.field.options.map(option =>
                <MenuItem value={option.value} key={option.label}>
                  {option.label}
                </MenuItem>)}
            </Select>
            <FormHelperText id={"error-client-" + fieldName}>
              {error?.message}
            </FormHelperText>
          </FormControl>
        </Grid2>
      )}
    />
  </>
}