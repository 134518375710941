import {useAuth} from "../../context/AuthContext";
import React, {useEffect, useState} from "react";
import {UserLoginDto, userLoginSchema} from "./dto/userLogin.dto";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import Header from "../../components/layout/Header";
import {Alert, Button, ButtonGroup, Card, CardContent, CardHeader} from "@mui/material";
import ApiCallResults from "../../components/utils/ApiCallResult";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Unstable_Grid2";
import FormFieldComp from "../../components/form/FormFieldComponent";
import {yupResolver} from "@hookform/resolvers/yup";
import {Link} from "react-router-dom";
import {AppRoutes} from "../../utils/AppRoutes";

export default function LoginPage() {
  const {onLogin, isLoggedIn} = useAuth()
  const methods = useForm<UserLoginDto>({
    resolver: yupResolver(userLoginSchema)
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (isLoggedIn()) {
      window.location.href = AppRoutes.HOME;
    }
  })

  const submitForm: SubmitHandler<UserLoginDto> = async (data) => {
    setLoading(true);
    if (methods.formState.isValid) {
      if (!await onLogin(data)) {
        setError('Gebruiker niet gevonden, of de combinatie van gebruikersnaam en wachtwoord is niet correct.')
      }
    }

    setLoading(false);
  }
  // todo: figure out why this is needed, otherwise form isn't evaluated in time...
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = methods.formState.isValid;

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitForm)}>
          <Header title={`Login`}></Header>
          <ApiCallResults loading={loading} error={undefined}/>
          <Grid2
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid2 sm={5}>
              <Box
                sx={{py: 1, px: 1}}>
                <Card>
                  <CardHeader
                    title={'Login'}
                  />
                  <CardContent>
                    {(error) && (
                      <>
                        <Alert severity="error">{`${error}`}</Alert><br/>
                      </>
                    )}

                    <Grid2 md={12}>
                      <FormFieldComp field={{
                        name: 'username',
                        inputType: 'text',
                        required: true,
                        label: 'Emailadres'
                      }}/>
                    </Grid2>
                    <Grid2 md={12}>
                      <FormFieldComp field={{
                        name: 'password',
                        inputType: 'password',
                        required: true,
                        label: 'Wachtwoord'
                      }}/>
                    </Grid2>
                    <ButtonGroup aria-label="outlined primary button group"
                                 style={{
                                   display: 'flex',
                                   justifyContent: 'flex-end',
                                 }}>
                      <Button component={Link}
                              to={AppRoutes.USER.FORGOT_PASSWORD}
                              color={'warning'}
                      >
                        Wachtwoord vergeten
                      </Button>
                      <Button component={Link}
                              to={AppRoutes.USER.REGISTER}
                              color="primary">
                        Account aanmaken
                      </Button>
                      <Button type="submit"
                              color="success">
                        Inloggen
                      </Button>
                    </ButtonGroup>
                  </CardContent>
                </Card>
              </Box>
            </Grid2>
          </Grid2>


        </form>
      </FormProvider>
    </>
  )
}