import * as yup from "yup"

export const userPasswordResetSchema = yup.object({
  email: yup.string()
    .email('Voer een geldig email adres in')
    .required('Email is verplicht'),
  code: yup.string(),
  newPassword: yup.string()
    .matches(
      /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
      , `Wachtwoord moet minimaal 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal karakter bevatten`),
})

export interface UserPasswordResetDto extends yup.InferType<typeof userPasswordResetSchema> {
}