import {DateTimeFormatter, OffsetDateTime, ZoneId} from "@js-joda/core";

const DATE_FORMAT = DateTimeFormatter
  .ofPattern('d-M-yyyy')

const DATE_TIME_FORMAT = DateTimeFormatter.ofPattern('d-M-yyyy H:mm')

export const formatDateString = (str: string): string => {
  if (str === null || str === "" || str === undefined) {
    return ""
  } else {
    return OffsetDateTime.parse(str)
      .atZoneSameInstant(ZoneId.SYSTEM)
      .format(DATE_FORMAT)
  }
}

export const formatDateTimeString = (str: string): string => {
  if (str === null || str === "" || str === undefined) {
    return ""
  } else {
    return OffsetDateTime.parse(str)
      .atZoneSameInstant(ZoneId.SYSTEM)
      .format(DATE_TIME_FORMAT)
  }
}