import {CompanyDto} from "../../dto/company.dto";
import {UserDto} from "../../dto/user.dto";

export function getLogo(user: UserDto | null) {
  let logo = (user?.company as CompanyDto)?.settings.logo
  if (logo) {
    return logo
  } else {
    return 'https://placehold.co/600x400' // todo: find a better placeholder
  }
}