import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {OffsetDateTime} from "@js-joda/core";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {useAuth} from "../../context/AuthContext";
import {InvoiceDto} from "../../dto/invoice.dto";
import {ApiError} from "../../service/apiUtils";
import {getInvoiceStillDue} from "../../service/invoice.service";
import {formatDateString} from "../../config/date-util";
import ApiCallResults from "../utils/ApiCallResult";

export default function InvoiceToBePaidList() {
  const {token} = useAuth();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState<InvoiceDto[]>([]);
  const [error, setError] = useState<ApiError>({hasError: false} as ApiError);

  useEffect(() => {
    async function getData() {
      if (loading) {
        const {data, error} = await getInvoiceStillDue(token);
        if (error.hasError) {
        } else {
          setInvoices(data)
        }
        setError(error)
        setLoading(false)
      }
    }

    getData()
  }, [loading, token])

  function invoiceOverDue(invoiceDueDate: string | null) {
    if (invoiceDueDate !== null) {
      const dueBy = OffsetDateTime.parse(invoiceDueDate);
      const today = OffsetDateTime.now();
      if (dueBy.isBefore(today)) {
        return true;
      }
    }
    return false;
  }

  return <Card>
    <CardHeader title="Openstaande facturen"/>
    <CardContent>
      <ApiCallResults loading={loading} error={error}/>

      <TableContainer className="open-invoice-table">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Factuurnummer</TableCell>
              <TableCell>Bedrijf</TableCell>
              <TableCell>Bedrag</TableCell>
              <TableCell>Te voldoen voor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((row: InvoiceDto) => (
              <TableRow
                key={row.id}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th" scope="row">
                  <Button component={Link}
                          to={`/invoice/${row.id}`}>
                    {row.invoiceNumber}
                  </Button>
                </TableCell>
                <TableCell>{row.client.name}</TableCell>
                <TableCell>&euro; {row.sumTotal}</TableCell>
                <TableCell
                  className={invoiceOverDue(row.invoiceDueDate) ? "overdue" : ""}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: 'inherit',
                    justifyContent: 'space-between'
                  }}>
                    {row.invoiceDueDate !== null && formatDateString(row.invoiceDueDate)} {invoiceOverDue(row.invoiceDueDate) &&
                    <>
                      - Te laat <ReportProblemOutlinedIcon style={{color: 'red'}}/>
                    </>
                  }
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </CardContent>
    <CardActions>
      <ButtonGroup>
        <Button component={Link}
                to={`/invoice`}
                color="primary">
          Naar facturen
        </Button>
      </ButtonGroup>
    </CardActions>
  </Card>

}