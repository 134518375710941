import {Box, Card, CardContent, CardHeader, Collapse} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import FormFieldDatePickerComponent from "../../components/form/FormFieldDatePickerComponent";
import FormFieldComp from "../../components/form/FormFieldComponent";
import React, {useState} from "react";
import {BasicFormField, FormDateField} from "../../components/utils/BasicFormField";
import {CreateInvoiceDto} from "../../dto/invoice.dto";
import {ExpandMore} from "../../components/layout/CardExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const invoiceDetailsFields: BasicFormField<CreateInvoiceDto>[] = [
  {
    name: 'invoiceNumber',
    inputType: 'numeric',
    required: true,
    label: 'Factuur nummer'
  },
  {
    name: 'assignment',
    inputType: 'text',
    required: true,
    label: 'Factuur opdracht'
  }
]
const invoiceDateField: FormDateField<CreateInvoiceDto> =
  {
    name: 'invoiceDate',
    required: true,
    label: 'Factuur datum'
  }

export default function () {
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return <>
    <Box sx={{py: 1, px: 1}}>
      <Card>
        <CardHeader title="Factuur details" action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon/>
          </ExpandMore>
        }/>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid2 container className="details">
              <FormFieldDatePickerComponent
                field={invoiceDateField}
                key={invoiceDateField.name}
              />
              {invoiceDetailsFields.map(field =>
                <Grid2 md={12} key={field.name}>
                  <FormFieldComp field={field}/>
                </Grid2>
              )}
            </Grid2>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  </>
}