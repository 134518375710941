import {AuthToken, invoiceAxios} from "../config/axios";
import {AxiosError} from "axios";


export interface ApiError {
  hasError: boolean
  status: number,
  message: string,
  details: any
}

export interface GetResult<T> {
  data: T,
  error: ApiError
}

export interface PostResult<T> {
  data: T,
  error: ApiError
}

export async function apiGet<T>(path: string, token: AuthToken): Promise<GetResult<T>> {
  try {
    const response = await invoiceAxios(token).get(path)
    return {
      data: response.data as T,
      error: {hasError: false} as ApiError
    }
  } catch (e) {
    return {
      data: {} as T,
      error: {
        hasError: true,
        message: (e as AxiosError).message
      } as ApiError
    }
  }
}

export async function apiDelete<T>(path: string, token: AuthToken): Promise<GetResult<T>> {
  try {
    const response = await invoiceAxios(token).delete(path)
    return {
      data: response.data as T,
      error: {hasError: false} as ApiError
    }
  } catch (e) {
    return {
      data: {} as T,
      error: {
        hasError: true,
        message: (e as AxiosError).message
      } as ApiError
    }
  }
}

export async function apiPost<T>(path: string, token: AuthToken, body: any, config: any = {}): Promise<PostResult<T>> {
  try {
    const response = await invoiceAxios(token).post(path, body, config)
    console.log("response", response)
    return {
      data: response.data as T,
      error: {hasError: false} as ApiError
    }
  } catch (e) {
    const axiosError = (e as AxiosError)
    return {
      data: {} as T,
      error: {
        hasError: true,
        message: axiosError.message,
        details: axiosError.response?.data
      } as ApiError
    }
  }
}

export async function apiPut<T>(path: string, token: AuthToken, body: any, config: any = {}): Promise<PostResult<T>> {
  try {
    const response = await invoiceAxios(token).put(path, body, config)
    return {
      data: response.data as T,
      error: {hasError: false} as ApiError
    }
  } catch (e) {
    const axiosError = (e as AxiosError)
    return {
      data: {} as T,
      error: {
        hasError: true,
        message: axiosError.message,
        details: axiosError.response?.data
      } as ApiError
    }
  }
}

export {}