import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import Header from "../../components/layout/Header";
import ApiCallResults from "../../components/utils/ApiCallResult";
import Grid2 from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import {Alert, Button, ButtonGroup, Card, CardContent, CardHeader, Snackbar} from "@mui/material";
import FormFieldComp from "../../components/form/FormFieldComponent";
import {Link, useNavigate} from "react-router-dom";
import {AppRoutes} from "../../utils/AppRoutes";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {UserPasswordResetDto, userPasswordResetSchema} from "./dto/userPasswordReset.dto";
import {Auth} from "aws-amplify";

enum PasswordResetSteps {
  EMAIL,
  CODE,
  PASSWORD
}

export default function PasswordForgotPage() {
  const {isLoggedIn} = useAuth()
  const navigator = useNavigate()
  const methods = useForm<UserPasswordResetDto>({
    resolver: yupResolver(userPasswordResetSchema)
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [stateMachine, setStateMachine] = useState<PasswordResetSteps>(PasswordResetSteps.EMAIL)
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isLoggedIn()) {
      window.location.href = AppRoutes.HOME;
    }
  })
  const closeNotification = () => {
    setMessage(null)
  }

  async function sendResetCode(data: UserPasswordResetDto) {
    try {
      await Auth.forgotPassword(data.email)
      setStateMachine(PasswordResetSteps.CODE)
      setMessage("Er is een email verstuurd met een code om het wachtwoord te resetten.")
    } catch (e) {
      console.log("Error resetting password: ", e)
      setError("Fout bij het resetten van het wachtwoord, probeer het later nog eens.")
    }
  }

  async function resetPassword(data: UserPasswordResetDto) {
    if (data.code === undefined || data.newPassword === undefined) {
      setError("Fout bij het resetten van het wachtwoord, probeer het later nog eens.")
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(data.email, data.code, data.newPassword)
      setStateMachine(PasswordResetSteps.PASSWORD)
      navigator(AppRoutes.USER.LOGIN, {
        state: {
          message: 'Wachtwoord is succesvol gereset, u kunt nu inloggen.',
          severity: 'success'
        }
      })

    } catch (e) {
      console.log("Error resetting password: ", e)
      setError("Fout bij het resetten van het wachtwoord, probeer het later nog eens.")
    }
  }

  const submitForm: SubmitHandler<UserPasswordResetDto> = async (data) => {
    setLoading(true);
    switch (stateMachine) {
      case PasswordResetSteps.EMAIL:
        await sendResetCode(data)
        break;
      case PasswordResetSteps.CODE:
        await resetPassword(data)
        break;
      default:
        console.log("unknown step");
    }
    setLoading(false);
  }
  // todo: figure out why this is needed, otherwise form isn't evaluated in time...
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = methods.formState.isValid;


  return <>
    <Snackbar open={!loading && message !== null} autoHideDuration={2000} onClose={closeNotification}>
      <Alert severity="success">{message}</Alert>
    </Snackbar>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitForm)}>
        <Header title={`Wachtwoord vergeten`}></Header>
        <ApiCallResults loading={loading} error={undefined}/>
        <Grid2
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid2 sm={5}>
            <Box
              sx={{py: 1, px: 1}}>
              <Card>
                <CardHeader
                  title={'Wachtwoord vergeten'}
                />
                <CardContent>
                  {(error) && (
                    <>
                      <Alert severity="error">{`${error}`}</Alert><br/>
                    </>
                  )}

                  <Grid2 md={12}>
                    <FormFieldComp field={{
                      name: 'email',
                      inputType: 'text',
                      required: true,
                      label: 'Emailadres'
                    }}/>
                  </Grid2>

                  {stateMachine === PasswordResetSteps.CODE &&
                    <>
                      <Grid2 md={12}>
                        <FormFieldComp field={{
                          name: 'code',
                          inputType: 'text',
                          required: true,
                          label: 'Code'
                        }}/>
                      </Grid2>
                      <Grid2 md={12}>
                        <FormFieldComp field={{
                          name: 'newPassword',
                          inputType: 'password',
                          required: true,
                          label: 'Nieuw wachtwoord'
                        }}/>
                      </Grid2>
                    </>
                  }

                  <ButtonGroup aria-label="outlined primary button group"
                               style={{
                                 display: 'flex',
                                 justifyContent: 'flex-end',
                               }}>
                    <Button component={Link}
                            to={AppRoutes.USER.LOGIN}
                    >
                      Inloggen
                    </Button>
                    <Button type="submit"
                            color="success"
                    >
                      Wachtwoord resetten
                    </Button>
                  </ButtonGroup>
                </CardContent>
              </Card>
            </Box>
          </Grid2>
        </Grid2>


      </form>
    </FormProvider>
  </>

}