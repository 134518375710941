import {createContext, useContext, useState} from "react";
import {ApiError} from "../service/apiUtils";
import {UserDto} from "../dto/user.dto";

const ApplicationContext = createContext({} as IApplicationContextProps);

const INITIAL_STATE: IApplicationState = {
  apiErrors: null,
  isMobileOpen: false,
  tabIndex: 0,
  features: null,
  user: null
}

export interface Feature {
  name: string,
  enabled: boolean
}

export interface IApplicationState {
  apiErrors: ApiError[] | null,
  isMobileOpen: boolean,
  tabIndex: number | null
  features: Feature[] | null
  user: UserDto | null
}

export interface ApplicationContextActions {
  setApiErrors(apiErrors: ApiError[] | null): void

  setMobileOpen(isMobileOpen: boolean): void

  setTabIndex(tabIndex: number): void

  setFeatures(features: Feature[]): void

  setUser(user: UserDto): void
}

export interface IApplicationContextProps extends IApplicationState, ApplicationContextActions {
}

export function ApplicationContextProvider(props: any) {
  const [application, setApplication] = useState<IApplicationState>(INITIAL_STATE);

  const handleSetApiErrors = (apiErrors: ApiError[] | null) => {
    setApplication({...application, apiErrors})
  }

  const handleSetMobileOpen = (isMobileOpen: boolean) => {
    setApplication({...application, isMobileOpen})
  }

  const handleSetTabIndex = (tabIndex: number) => {
    setApplication({...application, tabIndex})
  }

  const handleSetFeatures = (features: Feature[]) => {
    setApplication({...application, features})
  }

  const handleSetUser = (user: UserDto) => {
    setApplication({...application, user})
  }

  return (
    <ApplicationContext.Provider value={{
      ...application,
      setApiErrors: handleSetApiErrors,
      setMobileOpen: handleSetMobileOpen,
      setTabIndex: handleSetTabIndex,
      setFeatures: handleSetFeatures,
      setUser: handleSetUser
    }}>
      {props.children}
    </ApplicationContext.Provider>
  )
}

export const useApplicationContext = () => {
  return useContext(ApplicationContext);
}