import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {getEmailTemplates} from "../../../service/email-template.service";
import {useAuth} from "../../../context/AuthContext";
import {ApiError} from "../../../service/apiUtils";
import {EmailTemplateDto, EmailTemplateType} from "../../../dto/EmailTemplate.dto";
import ApiCallResults from "../../../components/utils/ApiCallResult";
import EmailTemplateEditor from "./EmailTemplateEditor";

export default function CompanyEditEmailTemplates() {
  const {token} = useAuth();
  const [templateIndex, setTemplateIndex] = useState(0);
  const [templates, setTemplates] = useState<EmailTemplateDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>({hasError: false} as ApiError);

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      const {data, error} = await getEmailTemplates(token)
      setError(error)
      setTemplates(data)
      setLoading(false)
    }

    loadData()
  }, [])

  const handleTemplateIndexChange = (event: React.SyntheticEvent, newValue: number) => {
    setTemplateIndex(newValue);
  }

  return <>
    <Box
      sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection: 'row', height: '80vh'}}
    >
      <ApiCallResults loading={loading} error={error}/>

      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={templateIndex}
        onChange={handleTemplateIndexChange}
        aria-label="Email templates"
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          width: '10%'
        }}
      >

        {templates.map((template, index) =>
          <Tab key={index} label={EmailTemplateType[template.pk.type]}/>
        )}
      </Tabs>

      {templates.filter((val, index) => templateIndex === index)
        .map((template, index) =>
          <EmailTemplateEditor
            key={index}
            template={template}/>
        )}
    </Box>


  </>
}