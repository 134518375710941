import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {ClientDto, clientSchema, CreateClientDto} from "../../dto/client.dto";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ApiCallResults from "../../components/utils/ApiCallResult";
import {ApiError, PostResult} from "../../service/apiUtils";
import {Box, Button, ButtonGroup, Card, CardContent, CardHeader, InputAdornment} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useAuth} from "../../context/AuthContext";
import {createClient, getClient, updateClient} from "../../service/client.service";
import {yupResolver} from '@hookform/resolvers/yup';
import {BasicFormField} from "../../components/utils/BasicFormField";
import FormFieldComp from "../../components/form/FormFieldComponent";
import Header from "../../components/layout/Header";
import {AppRoutes, getRouteWithParams} from "../../utils/AppRoutes";


const clientEditFields: BasicFormField<CreateClientDto>[] = [
  {
    name: 'name',
    inputType: 'text',
    required: true,
    label: 'Klant naam',
  },
  {
    name: 'tav',
    inputType: 'text',
    required: false,
    label: 'T.A.V.',
  },
  {
    name: 'email',
    inputType: 'text',
    required: false,
    label: 'Email',
  },
  {
    name: 'defaultHourRate',
    inputType: 'decimal',
    required: true,
    label: 'Standaard tarief',
    startAdornment: <InputAdornment position="start">€</InputAdornment>
  }
]

const clientAddressFields: BasicFormField<CreateClientDto>[] = [
  {
    name: "street",
    inputType: "text",
    required: true,
    label: "straatnaam en nummer"
  },
  {
    name: "zipCode",
    inputType: "text",
    required: true,
    label: "Postcode"
  },
  {
    name: "city",
    inputType: "text",
    required: true,
    label: "Stad"
  },
  {
    name: "country",
    inputType: "text",
    required: true,
    label: "Land"
  }
]

export default function ClientEditPage() {
  const param = useParams()
  const navigator = useNavigate()
  const {token} = useAuth()
  const [error, setError] = useState({} as ApiError)
  const methods = useForm<CreateClientDto>({
    resolver: yupResolver(clientSchema),
    defaultValues: {
      defaultHourRate: 0,
    }
  });
  const [loading, setLoading] = useState<boolean>(true)
  const [mode, setMode] = useState<string | null>(null)

  const onSubmit: SubmitHandler<CreateClientDto> = async data => {
    setLoading(true)
    let response: PostResult<ClientDto> = {} as PostResult<ClientDto>
    if (mode === "edit") {
      response = await updateClient(token, param.id as string, data)
    } else if (mode === "create") {
      response = await createClient(token, data)
    } else {
      console.error("Error state, unknown mode", mode)
    }
    console.log("server response", response)
    if (response.error.hasError) {
      setError(response.error)
    } else {
      navigator(getRouteWithParams(AppRoutes.CLIENT.VIEW, {id: response.data.id}), {
        state: {
          message: (mode === "edit" ? 'Klant succesvol bijgewerkt' : 'Klant succesvol aangemaakt'),
          severity: 'success'
        }
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    setMode(param.id !== undefined ? "edit" : "create")
    if (param.id !== undefined) {
      getClient(token, param.id)
        .then(client => {
          console.log(client)
          methods.reset({
            name: client.data.name,
            defaultHourRate: client.data.defaultHourRate,
            tav: client.data.tav ? client.data.tav : "",
            email: client.data.email ? client.data.email : "",
            city: client.data.city,
            street: client.data.street,
            country: client.data.country,
            zipCode: client.data.zipCode
          })
        })
    }
    setLoading(false)
  }, [methods, param.id, token])

  function getTitle() {
    return mode === "edit" ? "Klant bewerken" : "Klant aanmaken"
  }

  if (mode === null) {
    return <>
      <ApiCallResults loading={loading} error={{} as ApiError}/>
    </>
  }
  return <>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Header
          title={getTitle()}
        >
          <ButtonGroup aria-label="outlined primary button group">
            <Button size="small"
                    color="primary"
                    component={Link}
                    to={-1 as any}>
              Back
            </Button>
            <Button type="submit"
                    color="inherit"
                    size="small">
              Opslaan
            </Button>
          </ButtonGroup>
        </Header>

        <ApiCallResults loading={loading} error={error}/>

        <Box sx={{
          py: 1, px: 1
        }}>
          <Card>
            <CardHeader title={(<>Klant: <i>{methods.watch().name}</i></>)}/>
            <CardContent>
              <Grid2 container>
                {clientEditFields.map((field, index) =>
                  <Grid2 md={6} key={index}>
                    <FormFieldComp key={field.name}
                                   field={field}
                    /></Grid2>
                )}

              </Grid2>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{
          py: 1, px: 1
        }}>
          <Card>
            <CardHeader title={`Adres`}/>
            <CardContent>
              <Grid2 container>
                {clientAddressFields.map((field, index) =>
                  <Grid2 md={6} key={index}>
                    <FormFieldComp key={field.name}
                                   field={field}
                    />
                  </Grid2>
                )}
              </Grid2>
            </CardContent>
          </Card>
        </Box>

      </form>
    </FormProvider>
  </>
}
