import {AuthToken} from "../config/axios";
import {ClientDto, CreateClientDto} from "../dto/client.dto";
import {apiGet, apiPost} from "./apiUtils";

export const getClients = async (token: AuthToken) =>
  await apiGet<ClientDto[]>(`client`, token)

export const getClient = async (token: AuthToken, id: string) =>
  await apiGet<ClientDto>(`client/${id}`, token)

export const createClient = async (token: AuthToken, clientDto: CreateClientDto) =>
  await apiPost<ClientDto>(`client`, token, clientDto)

export const updateClient = async (token: AuthToken, id: string, dto: CreateClientDto) =>
  await apiPost<ClientDto>(`client/${id}`, token, dto)