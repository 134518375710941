import {useAuth} from "../../context/AuthContext";
import React, {useEffect, useState} from "react";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import Header from "../../components/layout/Header";
import ApiCallResults from "../../components/utils/ApiCallResult";
import Grid2 from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import {Alert, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader} from "@mui/material";
import FormFieldComp from "../../components/form/FormFieldComponent";
import {Link, useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {CreateAccountDto, CreateAccountSchema} from "./dto/userRegister.dto";
import {BasicFormField} from "../../components/utils/BasicFormField";
import {registerUser} from "../../service/user.service";
import {AppRoutes} from "../../utils/AppRoutes";

const registerUserFields: BasicFormField<CreateAccountDto>[] = [
  {
    name: 'user.email',
    inputType: 'text',
    required: true,
    label: 'Email adres'
  },
  {
    name: 'user.password',
    inputType: 'password',
    required: true,
    label: 'Wachtwoord'
  },
  {
    name: 'user.firstname',
    inputType: 'text',
    required: true,
    label: 'Voornaam'
  },
  {
    name: 'user.lastname',
    inputType: 'text',
    required: true,
    label: 'Achternaam'
  },
  {
    name: 'user.companyName',
    inputType: 'text',
    required: true,
    label: 'Bedrijfsnaam'
  }
]

export default function RegisterPage() {
  const {isLoggedIn} = useAuth()
  const navigator = useNavigate()

  const methods = useForm<CreateAccountDto>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(CreateAccountSchema)
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (isLoggedIn()) {
      window.location.href = AppRoutes.HOME;
    }
  })

  const submitForm: SubmitHandler<CreateAccountDto> = async (data) => {
    setLoading(true);
    if (methods.formState.isValid) {
      const result = await registerUser(data)
      if (result.error.hasError) {
        setError(result.error.message)
      } else {
        navigator(AppRoutes.USER.LOGIN, {
          state: {
            message: 'Registratie succesvol, u kunt nu inloggen.',
            severity: 'success'
          }
        })
      }
    } else {
      console.log("Form is not valid", methods.formState.errors)
    }
    setLoading(false);
  }

  // todo: figure out why this is needed, otherwise form isn't evaluated in time...
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = methods.formState.isValid;

  return <>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitForm)}>
        <Header title={`Registreren`}></Header>
        <ApiCallResults loading={loading} error={undefined}/>
        <Grid2
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid2 md={12}>
            {(error) && (
              <>
                <Alert severity="error">{`${error}`}</Alert><br/>
              </>
            )}
          </Grid2>
          <Grid2 md={6}>
            <Box
              sx={{py: 1, px: 1}}>
              <Card>
                <CardHeader
                  title={'Gebruikers gegevens'}
                />
                <CardContent>
                  {registerUserFields.map((field, index) => (
                    <Grid2 md={12} key={index}>
                      <FormFieldComp
                        key={field.name}
                        field={field}
                      />
                    </Grid2>
                  ))}
                </CardContent>
                <CardActions>
                  <ButtonGroup aria-label="outlined primary button group"
                               style={{
                                 display: 'flex',
                                 justifyContent: 'flex-end',
                               }}>
                    <Button component={Link}
                            to={'/user/login'}
                            color="primary">
                      Naar login
                    </Button>
                    <Button type="submit"
                            color="success">
                      Registreren
                    </Button>
                  </ButtonGroup>
                </CardActions>
              </Card>
            </Box>
          </Grid2>

        </Grid2>


      </form>
    </FormProvider>
  </>

}