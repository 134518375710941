import {Controller, useFormContext} from "react-hook-form";
import {FormDateField} from "../utils/BasicFormField";
import {FormControl, TextField} from "@mui/material";
import React from "react";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import AdapterJoda from '@date-io/js-joda';
import {LocalDateTime} from "@js-joda/core";
import Grid2 from "@mui/material/Unstable_Grid2";

export default function FormFieldDatePickerComponent(props: { field: FormDateField<any> }) {
  const methods = useFormContext<any>()
  const fieldName: string = props.field.name as string
  const error = methods.getFieldState(fieldName, methods.formState).error

  return <>
    <Controller name={fieldName}
                control={methods.control}
                defaultValue={LocalDateTime.now()}
                render={(({field: {onChange, value}, fieldState: {error}}) =>
                    <Grid2 md={12}>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterJoda}>
                          <DesktopDatePicker
                            label={props.field.label}
                            inputFormat="dd-MM-yyyy"
                            value={value}
                            onChange={event => onChange(event)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid2>
                )}
    />
  </>
}