import {BasicFormField} from "../utils/BasicFormField";
import {Controller, useFormContext} from "react-hook-form";
import Grid2 from "@mui/material/Unstable_Grid2";
import {FormControl, FormHelperText, InputLabel, TextField, TextFieldVariants} from "@mui/material";
import React from "react";

export default function FormFieldComp(props: {
  field: BasicFormField<any>,
  name?: string,
  style?: string,
  disabled?: boolean
}) {
  const methods = useFormContext<any>()
  const fieldName: string = props.name !== undefined ? props.name : props.field.name as string
  const error = methods.getFieldState(fieldName, methods.formState).error
  const disabled= props.disabled !== undefined ? props.disabled : false
  return <>
    <Controller
      name={fieldName}
      control={methods.control}
      defaultValue={''}
      render={({field}) => (
          <FormControl fullWidth>

            {props.field.inputType !== "textarea" &&
              <>
                <TextField {...field}
                           variant={props.style !== undefined ? (props.style as TextFieldVariants) : "outlined"}
                           error={error !== undefined}
                           id={"client-" + fieldName}
                           disabled={disabled}
                           required={props.field.required}
                           type={props.field.inputType}
                           label={props.field.label}
                           InputLabelProps={{shrink: true}}
                           InputProps={{
                             startAdornment: props.field.startAdornment
                           }}
                />
              </>
            }
            {props.field.inputType === "textarea" &&
              <TextField
                {...field}
                id={"client-" + fieldName}
                label={props.field.label}
                required={props.field.required}
                multiline
                rows={props.field.multiLineRows ? props.field.multiLineRows : 4}
                variant="outlined"
                error={error !== undefined}
              />
            }
            <FormHelperText id={"error-client-" + fieldName}>
              {error?.message}
            </FormHelperText>
          </FormControl>
      )}
    />
  </>
}