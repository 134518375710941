import * as yup from "yup";

export interface EmailTemplateDto {
  pk: {
    type: EmailTemplateType;
  }
  template: string;
  createdAt: string;
  updatedAt: string;
}

export enum EmailTemplateType {
  INVOICE = "Factuur" as any,
  PAYMENT_REMINDER = "Betaling herinnering" as any,
}

export const emailTemplateSchema = yup.object({
  "type": yup.mixed()
    .oneOf(Object.values(EmailTemplateType))
    .defined(),
  template: yup.string().required(`Template is verplicht`),
})

export interface EmailTemplateUpdateDto extends yup.InferType<typeof emailTemplateSchema> {
}