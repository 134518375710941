import {FieldArrayWithId, useFieldArray, UseFormReturn} from "react-hook-form";
import {CreateInvoiceDto} from "../../../dto/invoice.dto";
import {Button, Card, CardContent, CardHeader, Collapse, TableContainer} from "@mui/material";
import Box from "@mui/material/Box";
import {ExpandMore} from "../../../components/layout/CardExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InvoiceLineTableItem from "./invoiceLineTableItem";


export default function InvoiceLinesTable(props: {
  methods: UseFormReturn<CreateInvoiceDto, any>
}) {
  const control = props.methods.control

  const {fields, append, remove} = useFieldArray({
    name: "lines",
    control
  })

  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  }
  const addDistanceComp = () => {
    append({
      'type': 'KM vergoeding',
      amount: 0,
      price: 0.21,
      description: "Kilometer vergoeding"
    })
  }
  const addInvoiceLine = () => {
    append({
      'type': 'Uur',
      amount: 0,
      price: props.methods.getValues('hourRate'),
      description: ""
    })
  }

  return <>
    <Box sx={{py: 1, px: 1}}>
      <Card>
        <CardHeader title="Factuur regels" action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon/>
          </ExpandMore>
        }/>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <TableContainer component={CardContent}>
            <Table sx={{minWidth: '100%'}} size="small">
              <TableHead>
                <TableRow>
                  <TableCell width={'50%'}>Omschrijving</TableCell>
                  <TableCell width={"15%"}>Prijs</TableCell>
                  <TableCell width={"15%"}>Aantal</TableCell>
                  <TableCell width={"20%"}>Type</TableCell>
                  <TableCell width={'10px'}> - </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((f: FieldArrayWithId, index: number) => (
                  <InvoiceLineTableItem
                    key={f.id}
                    field={f}
                    index={index}
                    remove={remove}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{
            margin: '10px',
            float: 'right'
          }}>
            <Button onClick={addDistanceComp}>Kilometervergoeding toevoegen</Button>
            <Button onClick={addInvoiceLine}>Regel toevoegen</Button>
          </div>

        </Collapse>
      </Card>
    </Box>
  </>
}