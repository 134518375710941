import {InvoiceDto} from "../../../dto/invoice.dto";
import {Button, ButtonGroup} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {useAuth} from "../../../context/AuthContext";
import {deleteInvoice, sendInvoiceToCustomer} from "../../../service/invoice.service";
import {ApiError} from "../../../service/apiUtils";
import ApiCallResults from "../../../components/utils/ApiCallResult";
import {useApplicationContext} from "../../../context/ApplicationContext";
import {Features, isFeatureEnabled} from "../../../config/ApplicationFeatures";
import InvoicePreviousActions from "./invoicePreviousActionsComponent";
import ConfirmationDialog from "../../../components/utils/dialogs/ConfirmationDialog";
import {AppRoutes, getRouteWithParams} from "../../../utils/AppRoutes";

export default function InvoiceActionsComponent(props: {
  invoice: InvoiceDto
  downloadInvoice: Function,
  updateInvoicePaymentStatus: Function
}) {
  const appContext = useApplicationContext();
  const navigator = useNavigate()
  const {token} = useAuth()
  const [deleteInvoiceDialogOpen, setDeleteInvoiceDialogOpen] = useState<boolean>(false)
  const [sendMailDialogOpen, setSendMailDialogOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>({hasError: false} as ApiError);

  const handleDeleteInvoiceDialog = () => {
    setDeleteInvoiceDialogOpen(true)
  }
  const handleCancelDeleteInvoiceDialog = () => {
    setDeleteInvoiceDialogOpen(false)
  }

  const handleOpenMailDialog = () => {
    setSendMailDialogOpen(true)
  }
  const handleCloseMailDialog = () => {
    setSendMailDialogOpen(false)
  }

  const deleteInvoiceAction = async () => {
    setLoading(true)
    // do api call
    const result = await deleteInvoice(token, props.invoice.id)

    if (result.error.hasError) {
      setError(result.error)
      setDeleteInvoiceDialogOpen(false)
    } else {
      navigator(AppRoutes.INVOICE.LIST, {
        state: {
          message: 'Factuur verwijderd',
          severity: 'success'
        }
      })
    }
    setLoading(false)
  }


  async function sendInvoice() {
    console.log("Send invoice")
    handleCloseMailDialog()
    const result = await sendInvoiceToCustomer(token, props.invoice.id)
    if (result.error.hasError) {
      setError(result.error)
      navigator(getRouteWithParams(AppRoutes.INVOICE.VIEW, {id: props.invoice.id}), {
        state: {
          message: "Factuur kon niet worden verstuurd",
          severity: 'error'
        }
      })
    } else {
      navigator(getRouteWithParams(AppRoutes.INVOICE.VIEW, {id: props.invoice.id}), {
        state: {
          message: "Factuur is verstuurd",
          severity: 'success'
        }
      })
    }
  }

  return <>
    <ApiCallResults loading={loading} error={error}/>

    <InvoicePreviousActions invoice={props.invoice}/>

    <h3>Voer actie uit</h3>

    <ButtonGroup>
      {isFeatureEnabled(appContext.features!!, Features.COMPANY_EMAIL_CONFIGURATION) &&
        props.invoice.client.email !== "" &&
        <Button onClick={handleOpenMailDialog}>
          Factuur mailen
        </Button>
      }


      <Button onClick={props.downloadInvoice(props.invoice.id)}>
        Download
      </Button>

      {props.invoice.isPaid && <>
        <Button component={Link}
                to={``}
                onClick={props.updateInvoicePaymentStatus()}
                color="success">
          Betaald
        </Button>
      </>}
      {!props.invoice.isPaid && <>
        <Button component={Link}
                to={``}
                onClick={props.updateInvoicePaymentStatus()}
                color="error">
          Niet betaald
        </Button>
      </>}
      <Button onClick={handleDeleteInvoiceDialog}
              color="error">
        Verwijderen
      </Button>
    </ButtonGroup>

    <ConfirmationDialog
      title={`Verstuur factuur naar ${props.invoice.client.name} bevestigen`}
      message={`Verstuur de factuur naar ${props.invoice.client.email}`}
      onConfirm={sendInvoice}
      onCancel={handleCloseMailDialog}
      isOpen={sendMailDialogOpen}/>

    <ConfirmationDialog
      title={`Bevestig het verwijderen van factuur ${props.invoice.invoiceNumber}`}
      message={`Weet je zeker dat je deze factuur wilt verwijderen?`}
      onConfirm={deleteInvoiceAction}
      onCancel={handleCancelDeleteInvoiceDialog}
      isOpen={deleteInvoiceDialogOpen}/>
  </>
}