import * as yup from "yup"

export interface ClientDto {
  id: string,
  country: string,
  createdAt: string,
  defaultHourRate: number
  name: string,
  city: string,
  street: string,
  tav: string | null,
  email: string | null,
  updatedAt: string | null,
  zipCode: string
  company: {
    id: string
  },
}

export interface CreateClientDto extends yup.InferType<typeof clientSchema> {
}

export const clientSchema = yup.object({
  name: yup.string().required(),
  defaultHourRate: yup.number()
    .typeError('Moet een getal zijn')
    .positive('Getal moet positief zijn')
    .required(),
  tav: yup.string().notRequired(),
  email: yup.string()
    .email("Geen geldig email adres")
    .notRequired(),
  city: yup.string().required(),
  street: yup.string().required(),
  country: yup.string().required(),
  zipCode: yup.string().max(7).required(),
}).required()