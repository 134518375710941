import * as yup from "yup"

export const userLoginSchema = yup.object({
  username: yup.string().email('Voer een geldig email adres in')
    .required('Email is verplicht'),
  password: yup.string().required(
    'Wachtwoord is verplicht'
  )
})


export interface UserLoginDto extends yup.InferType<typeof userLoginSchema> {
}