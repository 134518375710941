import {Document, Page} from "react-pdf";
import {Box, Button, ButtonGroup, Card, CardContent, CardHeader} from "@mui/material";
import React, {useEffect, useState} from "react";
import {LocalDateTime, OffsetDateTime, ZoneOffset} from "@js-joda/core";
import {useFormContext} from "react-hook-form";
import {useAuth} from "../../../context/AuthContext";
import {CreateInvoiceDto} from "../../../dto/invoice.dto";
import {renderInvoicePreview} from "../../../service/invoice.service";
import NoPdfSelectedMessage from "./noPdfSelectedMessage";

export default function InvoicePreviewContainer() {
  const {token} = useAuth()
  const [loading, setLoading] = useState<boolean>(true)
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [preview, setPreview] = useState<string>("")
  const methods = useFormContext<any>()

  const [refreshFormState, setRefreshFormState] = useState<CreateInvoiceDto | undefined>(undefined)

  methods.watch(value => setRefreshFormState(value as CreateInvoiceDto))
  useEffect(() => {
    const timeOutId = setTimeout(async () => await reload(), 2000);
    return () => clearTimeout(timeOutId);
  }, [refreshFormState])

  const reload = async () => {
    setLoading(true)
    let dto: CreateInvoiceDto = methods.getValues()
    dto.invoiceDate = OffsetDateTime.of((dto.invoiceDate as LocalDateTime), ZoneOffset.UTC)
    const previewHtml = await renderInvoicePreview(token, dto)
    console.log("previewHtml", previewHtml)
    if (previewHtml.error !== undefined && previewHtml.error.hasError) {
      setLoading(false)
      return
    }
    const reader = new FileReader();
    reader.readAsDataURL(previewHtml.data);
    reader.onloadend = () => {
      setPreview(reader.result as string)
    }
    setLoading(false)
  }

  function onDocumentLoadSuccess({numPages}: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function onLoadError(error: Error) {
    console.log('some error while loading the pdf', error)
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return <>
    <Box sx={{py: 1, px: 1}}>
      <Card>
        <CardHeader title="Voorbeeld factuur"/>
        <CardContent>
          <Document className="preview"
                    file={preview}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onLoadError}
                    noData={<NoPdfSelectedMessage/>}
          >
            <Page pageNumber={pageNumber}/>
          </Document>
          <div className="controls">
            <p>
              Pagina {pageNumber || (numPages ? 1 : "--")} van {numPages || "--"}
            </p>

            <ButtonGroup>
              <Button type="button" variant="contained" disabled={pageNumber <= 1} onClick={previousPage}>
                Vorige
              </Button>
              <Button color="success"
                      type="button"
                      onClick={() => reload()}
              >
                Voorbeeld verversen
              </Button>
              <Button
                type="button"
                variant="contained"
                disabled={numPages ? pageNumber >= numPages : true}
                onClick={nextPage}
              >
                Volgende
              </Button>
            </ButtonGroup>
          </div>
        </CardContent>
      </Card>
    </Box>
  </>
}