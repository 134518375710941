import {useAuth} from "../../context/AuthContext";
import {useEffect} from "react";

export default function LogoutPage() {
  const {onLogout} = useAuth()

  useEffect(() => {
    onLogout();
  })
  return (
    <>
      <h1>Logout!</h1>
    </>
  )
}