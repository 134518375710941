import {createContext, useContext, useState} from "react";
import {Auth} from "aws-amplify";
import {CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";
import {useLocation, useNavigate} from "react-router-dom";
import {AuthPermission} from "./authPermission";
import {UserLoginDto} from "../pages/user/dto/userLogin.dto";

const AuthContext = createContext({} as IAuthContextProps);

const INITIAL_STATE: IAuthState = {
  loading: true,
  token: null,
  userId: null,
  permissions: []
}

export interface IAuthState {
  loading: boolean
  token: string | null
  userId: string | null
  permissions: string[]
}

export interface AuthActions {
  onLogin(loginDto: UserLoginDto): Promise<boolean>

  onLogout(): void

  isLoggedIn(): boolean

  isAdmin(): boolean

  hasPermission(permission: AuthPermission): boolean
}

export interface IAuthContextProps extends IAuthState, AuthActions {
}

export function AuthContextProvider(props: any) {
  const [auth, setAuth] = useState<IAuthState>(() => createAuthFromSession(props.session));
  const navigate = useNavigate()
  const location = useLocation();

  function createAuthFromSession(session: CognitoUserSession | null) {
    const auth = INITIAL_STATE
    auth.loading = false
    if (session) {
      let payload = session.getAccessToken().decodePayload();
      auth.token = session.getAccessToken().getJwtToken();
      auth.permissions = payload['cognito:groups'];
      auth.userId = payload['sub'];
    }
    return auth;
  }

  const fillUserAuth = (session: CognitoUserSession) => {
    const auth = createAuthFromSession(session);
    setAuth(auth);
  }

  const handleLogin = async (loginDto: UserLoginDto): Promise<boolean> => {
    try {
      let user = (await Auth.signIn(loginDto.username, loginDto.password)) as CognitoUser
      // if ((user.challengeName === 'NEW_PASSWORD_REQUIRED')) {
      //   await Auth.completeNewPassword(user, 'Test1234!') // only when the user needs to change the password for the first time./..
      // }
      // console.log('Logged in!', user)
      user.getSession((err: Error | null, session: CognitoUserSession) => {
        if (err) {
          console.error("error", err)
        }
        fillUserAuth(session)
        console.log("Done! Authenticated!")
        const origin = location.state?.from?.pathname || '/';
        navigate(origin, {
          state: {
            message: "Succesvol ingelogd",
            severity: 'success'
          }
        });
      })
    } catch (err) {
      console.log("Error while logging in", err)
    }
    return false;
  }
  const handleLogout = async () => {
    try {
      await Auth.signOut();
      setAuth({
        loading: false,
        token: null,
        userId: null,
        permissions: []
      })
      console.log("Logged out!")
      navigate('/user/login')
    } catch (err) {
      console.error("Couldn't log out", err)
    }
  }
  const isAuthenticated = () => !auth.loading && auth.userId !== null && auth.token !== null
  const hasAdminPrivileges = () => auth.permissions.find(permission => permission === AuthPermission.ADMIN) !== undefined
  const hasPermission = (permission: AuthPermission) => auth.permissions.find(p => p === permission) !== undefined

  const value: IAuthContextProps = {
    loading: auth.loading,
    userId: auth.userId,
    token: auth.token,
    permissions: auth.permissions,
    onLogin: handleLogin,
    onLogout: handleLogout,
    isLoggedIn: isAuthenticated,
    isAdmin: hasAdminPrivileges,
    hasPermission: hasPermission
  }

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext<IAuthContextProps>(AuthContext)
}
