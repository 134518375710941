import {
  EmailTemplateDto,
  emailTemplateSchema,
  EmailTemplateType,
  EmailTemplateUpdateDto
} from "../../../dto/EmailTemplate.dto";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import React, {useEffect, useState} from "react";
import {Alert, Button, Snackbar, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import FormFieldComp from "../../../components/form/FormFieldComponent";
import ApiCallResults from "../../../components/utils/ApiCallResult";
import {ApiError} from "../../../service/apiUtils";
import Box from "@mui/material/Box";
import {getEmailTemplateVariables, updateTemplate} from "../../../service/email-template.service";
import {useAuth} from "../../../context/AuthContext";
import Grid2 from "@mui/material/Unstable_Grid2";
import Grid from "@mui/material/Grid";

interface Props {
  template: EmailTemplateDto
}

export default function EmailTemplateEditor(props: Props) {
  const {token} = useAuth();
  const [templateVariables, setTemplateVariables] = useState<Map<string, string>>(new Map<string, string>());

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>({hasError: false} as ApiError);
  const [message, setMessage] = useState<string | null>(null);

  const methods = useForm<EmailTemplateUpdateDto>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      template: props.template.template,
      "type": props.template.pk.type
    },
    resolver: yupResolver(emailTemplateSchema),
  });

  useEffect(() => {
    async function loadTemplateVariables() {
      const {data, error} = await getEmailTemplateVariables(token)
      setTemplateVariables(data)
    }

    loadTemplateVariables()
  }, [loading])

  const submitData: SubmitHandler<EmailTemplateUpdateDto> = async data => {
    setLoading(true)
    console.log("Update email template", data)
    const {error} = await updateTemplate(token, data);
    setError(error)
    if (!error.hasError) {
      setMessage("Template opgeslagen")
    }
    setLoading(false)
  }

  const closeNotification = () => {
    setMessage(null)
  }

  return <>
    <ApiCallResults loading={loading} error={error}/>
    <Snackbar open={!loading && message !== null} autoHideDuration={2000} onClose={closeNotification}>
      <Alert severity="success">{message}</Alert>
    </Snackbar>
    <Box sx={{p: 2, flexGrow: 1}} >
      <h1>Bewerkt template <i>{EmailTemplateType[props.template.pk.type]}</i></h1>
      <br/><br/>
      <Grid container spacing={2}>
        <Grid xs={6} item style={{padding: "25px"}}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submitData)}>
              <Grid2 md={12}>
                <FormFieldComp field={{
                  name: "template",
                  label: "Template",
                  required: true,
                  inputType: "textarea",
                  multiLineRows: 20,
                }}
                />
              </Grid2>

              <Button type="submit"
                      color="success"
                      size="small">
                Opslaan
              </Button>
            </form>
          </FormProvider>
        </Grid>
        <Grid xs={6} item style={{padding: "25px"}}>
          Template variabelen:
          <TableContainer style={{maxHeight: "50vh", width: "inherit"}}>
            <Table sx={{width: "100%"}}>
              <TableBody>

                {Object.entries(templateVariables).map(([key, value]) => (
                  <TableRow
                    key={key}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      <b>%{key}%</b>
                    </TableCell>
                    <TableCell>
                      {value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  </>
}