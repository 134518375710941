import {useAuth} from "../../../context/AuthContext";
import React, {useEffect, useState} from "react";
import {UserDto} from "../../../dto/user.dto";
import {getUserSelf} from "../../../service/user.service";
import {ApiError} from "../../../service/apiUtils";
import ApiCallResults from "../../../components/utils/ApiCallResult";
import {CompanyDto} from "../../../dto/company.dto";

import Grid2 from "@mui/material/Unstable_Grid2";
import CompanyInformation from "../components/companyInformation";
import {Box} from "@mui/material";
import InvoiceToBePaidList from "../../../components/invoice/InvoiceToBePaidList";
import Header from "../../../components/layout/Header";

export default function CompanyIndexPage() {
  const {token} = useAuth();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserDto | null>(null);
  const [error, setError] = useState<ApiError>({hasError: false} as ApiError);

  useEffect(() => {
    async function getData() {
      if (loading) {
        const {data, error} = await getUserSelf(token);
        setUser(data)
        setError(error)
        setLoading(false)
      }
    }

    getData()
  }, [loading, token])

  return (
    <>
      <Header
        title={`Mijn bedrijf - ${(user?.company as CompanyDto)?.name}`}>
      </Header>
      <Box sx={{
        py: 2, px: 2
      }}>
        <ApiCallResults loading={loading} error={error}/>
        <Grid2 container>
          <Grid2 xs={12} md={6}>
            <CompanyInformation user={user}/>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <InvoiceToBePaidList/>
          </Grid2>
        </Grid2>
      </Box>
    </>
  )
}
