export const AppRoutes = {
  HOME: '/',
  NOT_FOUND: '/404',
  USER: {
    LOGIN: '/user/login',
    REGISTER: '/user/register',
    LOGOUT: '/user/logout',
    FORGOT_PASSWORD: '/user/forgot-password',
  },
  CLIENT: {
    LIST: '/client',
    CREATE: '/client/new',
    VIEW: '/client/:id',
  },
  INVOICE: {
    LIST: '/invoice',
    CREATE: '/invoice/new',
    VIEW: '/invoice/:id',
  },
  COMPANY: {
    EDIT: '/company/edit',
  },
}

export function getRouteWithParams(route: string, params: any) {
  let result = route
  Object.keys(params).forEach((key) => {
    result = result.replace(`:${key}`, params[key])
  })
  return result
}

export const AppPublicRoutes = [
  AppRoutes.USER.LOGIN,
  AppRoutes.USER.REGISTER,
  AppRoutes.USER.LOGOUT,
  AppRoutes.USER.FORGOT_PASSWORD,
  AppRoutes.NOT_FOUND
]

export function isRoutePublic(route: string) {
  return AppPublicRoutes.includes(route)
}

export function isRoutePrivate(route: string) {
  return !isRoutePublic(route)
}