import {InvoiceDto} from "../../../dto/invoice.dto";
import React from "react";
import {formatDateString} from "../../../config/date-util";

export default function InvoiceDetailsComponent(props: {
  invoice: InvoiceDto
}) {
  const {invoice} = props;
  return <>
    <h2>Factuur {invoice.invoiceNumber}</h2>
    <span>Opdracht: {invoice.assignment}</span><br/>
    <span>Klant: {invoice.client.name}</span><br/>
    <span>Factuur is aangemaakt op {formatDateString(invoice.createdAt)}</span><br/>

    {invoice.isPaid && <span>Factuur is voldaan</span>}
    {!invoice.isPaid &&
      <span>Factuur nog niet voldaan, te voldoen voor {formatDateString(invoice.invoiceDueDate!)}</span>}
  </>
}