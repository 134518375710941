import * as yup from "yup";

export interface InvoiceLineDto {
  id: string,

  amount: number,
  price: number,
  description: string,
  "type": string
  company: {
    id: string
  },
  client: {
    id: string
  },
  createdAt: string,
  updatedAt: string | null,
}

export const InvoiceLineType = [
  "Uur",
  "Stuk",
  "KM vergoeding",
]


export interface CreateInvoiceLineDto extends yup.InferType<typeof invoiceLineSchema> {
}

export const invoiceLineSchema = yup.object({
  amount: yup.number()
    .typeError('Moet een getal zijn')
    .positive('Getal moet positief zijn')
    .required(),
  price: yup.number()
    .typeError('Moet een getal zijn')
    .when("type", {
      is: "Stuk",
      then: yup.number()
        .positive('Getal moet positief zijn')
    })
    .required(),
  description: yup.string()
    .required('Omschrijving is verplicht'),
  "type": yup.mixed()
    .oneOf(InvoiceLineType)
    .defined()
});