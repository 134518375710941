import {AuthToken} from "../config/axios";
import {apiPost, apiPut} from "./apiUtils";
import {CompanyDto, companyLogoUploadDto, UpdateCompanySettingsDto} from "../dto/company.dto";

export const getCompanyLogoUploadUrl = async (token: AuthToken, data: companyLogoUploadDto) =>
  await apiPost<string>(`company/logo-upload-url`, token, data)

export const updateCompanyLogo = async (token: AuthToken, data: companyLogoUploadDto) =>
  await apiPut<string>(`company/logo`, token, data)

export const updateCompanySettings = async (token: AuthToken, id: string, data: UpdateCompanySettingsDto) =>
  await apiPut<CompanyDto>(`company/${id}/settings`, token, data)