/*
 * Returns 1 if the IBAN is valid 
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */

export function isValidIban(input: string) {
  try {
    input = input.replace(/\s+/g, '')
    return BigInt([...input.slice(4), ...input.slice(0, 4)]
      .map(c => /[a-z]/i.test(c) ? c.toLowerCase().charCodeAt(0) - 87 : c)
      .join('')) % 97n === 1n
  } catch (e) {
    return false
  }
}
