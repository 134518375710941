import * as React from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";

interface ProtectedRouteProps {
  children?: JSX.Element | null
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.isLoggedIn()) {
    return props.children ? props.children : <Outlet/>
  } else {
    console.log('Redirect', auth)
    return <Navigate to="/login" state={{from: location}} replace/>;
  }
}