import {InvoiceDto} from "../../dto/invoice.dto";
import {DataGrid} from "@mui/x-data-grid";
import {invoiceListHeaderColumns} from "./invoiceListHeaderColumns";
import React, {useState} from "react";
import {downloadInvoice, invoiceUpdatePaymentStatus} from "../../service/invoice.service";
import {useAuth} from "../../context/AuthContext";
import {ApiError} from "../../service/apiUtils";
import ApiCallResults from "../utils/ApiCallResult";
import {Stack} from "@mui/material";

interface Props {
  invoices: InvoiceDto[]
}

export default function InvoiceTable(props: Props) {
  const {token} = useAuth()
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>({hasError: false} as ApiError);
  const [data, setData] = useState<InvoiceDto[]>(props.invoices);

  const updateInvoicePaymentStatus = (id: string) => async () => {
    setLoading(true)
    const result = await invoiceUpdatePaymentStatus(token, id)
    if (result.error.hasError) {
      setError(result.error)
    } else {
      const invoice = data.find(i => i.id === id)
      console.log(data)
      invoice!!.isPaid = !invoice!!.isPaid;
      setData([...data.filter(i => i.id !== id), invoice!!])
    }
    setLoading(false)
  }
  const downloadInvoiceFunc = (id: string) => async () => {
    await downloadInvoice(token, id);
  }

  return <>
    <ApiCallResults loading={loading} error={error}/>
    <DataGrid
      className="datagrid"
      rows={data}
      columns={invoiceListHeaderColumns(updateInvoicePaymentStatus, downloadInvoiceFunc)}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      components={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            Geen facturen gevonden
          </Stack>
        ),
      }}
    />
  </>
}