import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";

interface ActionDialogProps {
  title: string,
  message: string | React.ReactNode,
  isOpen: boolean,
  children: React.ReactNode
}

export default function ActionDialog(props: ActionDialogProps) {
  const {title, message,  isOpen} = props
  return <>
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        {props.children}
      </DialogActions>
    </Dialog>
  </>
}