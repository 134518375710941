import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import {Controller, UseFormReturn} from "react-hook-form";
import FormFieldComp from "../../components/form/FormFieldComponent";
import React, {useState} from "react";
import {CreateInvoiceDto} from "../../dto/invoice.dto";
import {BasicFormField} from "../../components/utils/BasicFormField";
import {ClientDto} from "../../dto/client.dto";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ExpandMore} from "../../components/layout/CardExpandMore";

const clientDetailsFields: BasicFormField<CreateInvoiceDto>[] = [
  {
    name: 'hourRate',
    inputType: 'numeric',
    required: true,
    label: 'Uurtarief'
  },
  {
    name: 'vatRate',
    inputType: 'numeric',
    required: true,
    label: 'BTW'
  }
]

export default function (props: {
  methods: UseFormReturn<CreateInvoiceDto, any>,
  clients: ClientDto[] | null
}) {
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const changeSelectedClient = (event: SelectChangeEvent) => {
    let clientId = event.target.value;
    props.methods.setValue("client", clientId)
    // console.log(event.target.value)
    let client = props.clients?.find(client => client.id === clientId)
    if (client !== undefined) {
      props.methods.setValue('hourRate', client?.defaultHourRate)
    }
    console.log(props.methods.getValues())
  };

  return <>
    <Box sx={{py: 1, px: 1}}>
      <Card>
        <CardHeader title="Klant" action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon/>
          </ExpandMore>
        }/>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid2 container className="details">
              <Grid2 md={12}>
                <FormControl fullWidth>
                  <InputLabel id="invoice-client">Klant</InputLabel>
                  <Controller
                    name="client"
                    defaultValue={''}
                    control={props.methods.control}
                    render={({field}: any) => (
                      <Select labelId="invoice-client" label='Klant' {...field}
                              onChange={changeSelectedClient}>
                        {props.clients?.map(client =>
                          <MenuItem value={client.id} key={client.id}>
                            {client.name}
                          </MenuItem>)}
                      </Select>
                    )}
                  />
                  <FormHelperText id={"error-invoice-client"}>
                    {props.methods.formState.errors['client']?.message}
                  </FormHelperText>
                </FormControl>
              </Grid2>
              {clientDetailsFields.map((field, index) =>
                <Grid2 md={12} key={`client-detail-fields-${index}`}>
                <FormFieldComp key={field.name}
                               field={field}
                               />
                </Grid2>
              )}
            </Grid2>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  </>
}
