import * as React from 'react';
import "./sidebar.scss"
import Divider from '@mui/material/Divider';
import Drawer, {DrawerProps} from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import {Dashboard, ExitToAppOutlined, Favorite, PersonOutlineOutlined, SettingsOutlined} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import {AppRoutes} from "../../utils/AppRoutes";

interface Category {
  id: string;
  admin?: boolean;
  children: CategoryItem[]
}

interface CategoryItem {
  id: string;
  icon: React.ReactNode;
  link: string;
  authenticated: boolean;
}

const categories: Category[] = [
  {
    id: 'Mijn bedrijf',
    children: [
      {
        id: 'Dashboard',
        icon: <Dashboard/>,
        link: AppRoutes.HOME,
        authenticated: true
      },
      // {
      //   id: 'Stats',
      //   icon: <InsertChartOutlined />,
      //   link: '/Stats',
      //   active: false,
      //   authenticated: true
      // },
      // {
      //   id: 'Notifications',
      //   icon: <NotificationsNoneOutlined />,
      //   link: '/Notifications',
      //   active: false,
      //   authenticated: true
      // },
      {
        id: 'Instellingen',
        icon: <SettingsOutlined/>,
        link: AppRoutes.COMPANY.EDIT,
        authenticated: true
      },
      {
        id: 'Login',
        icon: <LoginOutlinedIcon/>,
        link: AppRoutes.USER.LOGIN,
        authenticated: false
      },
      {
        id: 'Uitloggen',
        icon: <ExitToAppOutlined/>,
        link: AppRoutes.USER.LOGOUT,
        authenticated: true
      }
    ],
  },
  {
    id: 'Klanten',
    children: [
      {
        id: 'Klanten overzicht',
        icon: <PersonOutlineOutlined/>,
        link: AppRoutes.CLIENT.LIST,
        authenticated: true
      },
      {
        id: 'Klanten aanmaken',
        icon: <PersonAddAltOutlinedIcon/>,
        link: AppRoutes.CLIENT.CREATE,
        authenticated: true
      }
    ],
  },
  {
    id: 'Facturen',
    children: [
      {
        id: 'Facturen overzicht',
        icon: <InsertDriveFileOutlinedIcon/>,
        link: AppRoutes.INVOICE.LIST,
        authenticated: true
      },
      {
        id: 'Facturen aanmaken',
        icon: <NoteAddOutlinedIcon/>,
        link: AppRoutes.INVOICE.CREATE,
        authenticated: true
      }
    ],
  },
  {
    id: 'Admin',
    admin: true,
    children: [
      {
        id: 'System Health',
        icon: <Favorite/>,
        link: AppRoutes.HOME,
        authenticated: true,
      },
      {
        id: 'Settings',
        icon: <SettingsOutlined/>,
        link: AppRoutes.HOME,
        authenticated: true
      },
    ],
  }
];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Sidebar(props: DrawerProps) {
  const {...other} = props;
  const {isLoggedIn} = useAuth()


  function checkShowItem(authenticated: boolean) {
    return (authenticated && isLoggedIn())
      || (!authenticated && !isLoggedIn());
  }

  function showCategory(category: Category): boolean {
    if (category.admin) {
      return false
    }
    return category.children
      .filter(({authenticated}) => checkShowItem(authenticated))
      .length > 0
  }

  function isRouteActive(link: string) {
    return window.location.pathname === link;
  }

  return <>
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <Link to={AppRoutes.HOME}>
          <ListItem sx={{...item, ...itemCategory, fontSize: 22, color: '#fff'}}>
            C&W Facturen
          </ListItem>
        </Link>
        {categories
          .filter(category => showCategory(category))
          .map(({id, children}) => (
            <Box key={id} sx={{bgcolor: '#101F33'}}>
              <ListItem sx={{py: 2, px: 3}}>
                <ListItemText sx={{color: '#fff'}}>{id}</ListItemText>
              </ListItem>
              {children
                .filter(({authenticated, id}) => checkShowItem(authenticated))
                .map(({id: childId, icon, link}) => (
                  <Link to={link} key={childId}>
                    <ListItem disablePadding key={childId}>
                      <ListItemButton selected={isRouteActive(link)} sx={item}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText>
                          {childId}
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                ))}
              <Divider sx={{mt: 2}}/>
            </Box>
          ))}
      </List>
    </Drawer>

  </>;
}
