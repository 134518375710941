import axios, {AxiosInstance, AxiosResponse} from "axios";
import {AppRoutes} from "../utils/AppRoutes";

export type AuthToken = string | null

let axiosInstance: AxiosInstance | null = null

export const invoiceAxios = (token: string | null) => {
  const headers: any = {
    Accept: 'application/json',
  };
  if (token !== null) {
    headers['Authorization'] = `Bearer ${token}`
  }

  if (axiosInstance === null) {
    axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL ?? 'http://localhost:8080',
      headers: headers
    })
    registerMiddleware(axiosInstance)
  }
  return axiosInstance
}

function registerMiddleware(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors
    .response.use((response: AxiosResponse<any, any>) => response,
    error => {
      if (error.response.status === 401) {
        console.log("Unauthorized, redirecting to login")
        window.location.pathname = AppRoutes.USER.LOGIN
        window.location.reload();
      }
      throw error
    })
}