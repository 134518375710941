import React, {useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import {Amplify, Auth} from 'aws-amplify';
import {AuthContextProvider} from "./context/AuthContext";
import {CognitoUserSession} from "amazon-cognito-identity-js";
import {ThemeProvider} from "@mui/material";
import {theme} from "./config/theme";
import {ApplicationContextProvider} from "./context/ApplicationContext";
import AppComponent from "./AppComponent";
import {AppRoutes, isRoutePrivate} from "./utils/AppRoutes";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION || 'eu-west-1',
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || '',
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID || '',
    refreshHandlers: {
      'developer': cognitoRefresh(this)
    }
  },
});

function cognitoRefresh(session: any) {
  return () => {
    const params = {
      identityId: session.identityId,
      token: session.token,
    }
    return Promise.resolve(params)
  }
}

function App() {
  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState<CognitoUserSession | null>(null)


  useEffect(() => {
    Auth.currentSession()
      .then(session => {
        setSession(session);
      })
      .catch(e => {
        console.log("Auth session timeout, user not found. Redirecting to login")
        if (isRoutePrivate(window.location.pathname)) {
          window.location.pathname = AppRoutes.USER.LOGIN
          window.location.reload();
        }
      })
      .finally(() => {
        setLoading(false);
      })
  }, [loading])

  if (loading) {
    return <>
      Loading app...
    </>
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ApplicationContextProvider>
          <AuthContextProvider session={session}>
            <AppComponent/>
          </AuthContextProvider>
        </ApplicationContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
