import * as yup from "yup"
import {InvoiceLineDto, invoiceLineSchema} from "./invoiceLine.dto";
import InvoiceMailDto from "./InvoiceMail.dto";

export interface InvoiceDto {
  id: string,
  hourRate: number,
  vatRate: number,
  invoiceDate: Date,
  invoiceNumber: number,
  assignment: string,
  isPaid: boolean,
  withDistanceCompensation: boolean,
  distanceCompensationPrice: number,
  distanceCompensationKms: number,
  lines: InvoiceLineDto[]
  sumTotal: number | null,
  invoiceDueDate: string | null,
  mails: InvoiceMailDto[],
  company: {
    id: string
  },
  client: {
    id: string
    name: string | null
    email: string | null
  },
  createdAt: string,
  updatedAt: string | null,
}

export interface CreateInvoiceDto extends yup.InferType<typeof invoiceSchema> {
}

export const invoiceSchema = yup.object({
  client: yup.string().required(),
  hourRate: yup.number()
    .typeError('Moet een getal zijn')
    .positive('Getal moet positief zijn')
    .required(),
  vatRate: yup.number()
    .typeError('Moet een getal zijn')
    .positive('Getal moet positief zijn')
    .required(),
  invoiceDate: yup.mixed()
    .required(),
  invoiceNumber: yup.number()
    .min(0)
    .required(),
  assignment: yup.string().required(),
  withDistanceCompensation: yup.boolean(),
  distanceCompensationPrice: yup.number()
    .when('withDistanceCompensation', {
      is: true,
      then: schema => schema.typeError('Moet een getal zijn')
        .positive('Getal moet positief zijn')
        .required(),
      otherwise: schema => schema.notRequired()
    }),
  distanceCompensationKms: yup.number()
    .when('withDistanceCompensation', {
      is: true,
      then: schema => schema.typeError('Moet een getal zijn')
        .positive('Getal moet positief zijn')
        .required(),
      otherwise: schema => schema.notRequired()
    }),
  lines: yup.array().of(invoiceLineSchema)
}).required()