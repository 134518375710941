import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ClientDto} from "../../dto/client.dto";
import ApiCallResults from "../../components/utils/ApiCallResult";
import {useAuth} from "../../context/AuthContext";
import {getClient} from "../../service/client.service";
import {Box, Button, ButtonGroup, Card, CardContent, CardHeader} from "@mui/material";
import './client.scss'
import Grid2 from "@mui/material/Unstable_Grid2";
import {ApiError} from "../../service/apiUtils";
import {formatDateString} from "../../config/date-util";
import Header from "../../components/layout/Header";
import InvoiceTable from "../../components/invoice/InvoiceTable";
import {InvoiceDto} from "../../dto/invoice.dto";
import {invoicesForClient} from "../../service/invoice.service";


export default function ClientView() {
  const param = useParams()
  const {token} = useAuth()
  const [client, setClientData] = useState<ClientDto>({} as ClientDto);
  const [invoices, setInvoiceData] = useState<InvoiceDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ApiError>({hasError: false} as ApiError);

  useEffect(() => {
    async function getClientData(id: string) {
      await getInvoiceData(id);
      if (loading) {
        const result = await getClient(token, id)
        setClientData(result.data)
        setError(result.error)
        setLoading(false)
      }
    }

    async function getInvoiceData(id: string) {
      const result = await invoicesForClient(token, id)
      console.log("Got invoices: ", result.data)
      setInvoiceData(result.data)
      setError(result.error)
    }

    getClientData(param.id as string)
  }, [loading])

  const deleteClient = () => {
    console.log("delete client!")
  }

  return <>
    <Header
      title={`Klant ${client.name}`}
    >
      <ButtonGroup>
        <Button size="small"
                color="inherit"
                component={Link} to={'/client'}>Back</Button>
        <Button component={Link}
                size="small"
                color="inherit"
                to={`/client/${client.id}/edit`}>
          Edit
        </Button>
        <Button component={Link}
                size="small"
                color="inherit"
                to={"#"}
                onClick={deleteClient}>
          Verwijderen
        </Button>
      </ButtonGroup>
    </Header>
    <Box sx={{
      py: 1, px: 1
    }}>
      <ApiCallResults loading={loading} error={error}/>

      <Box sx={{
        py: 1, px: 1
      }}>
        <Card>
          <CardHeader title={"Details"}/>
          <CardContent>
            <Grid2 container>
              <Grid2 md={6}>
                <div>
                  <span className="title">Standaard tarief:</span> {client.defaultHourRate}
                </div>
              </Grid2>

              <Grid2 md={6}>
                <div>
                  <span className="title">tav: </span>
                  {client.tav && (<>{client.tav}</>)}
                  {!client.tav && (<>n.v.t.</>)}
                </div>
              </Grid2>
              <Grid2 md={6}>
                <div>
                  <span className="title">Email: </span>
                  {client.email && (<>{client.email}</>)}
                  {!client.email && (<>n.v.t.</>)}
                </div>
              </Grid2>

              <Grid2 md={12}>
                <hr/>
              </Grid2>

              <Grid2 md={6}>
                <div>
                  <span className="title">Aangemaakt op:</span> {formatDateString(client.createdAt)}
                </div>
              </Grid2>
              <Grid2 md={6}>
                <div>
                  <span className="title">Bijgewerkt op: </span>
                  {client.updatedAt && (<>{formatDateString(client.updatedAt)}</>)}
                  {!client.updatedAt && (<>n.v.t.</>)}
                </div>
              </Grid2>

            </Grid2>
          </CardContent>
        </Card>
      </Box>

      <Box sx={{
        py: 1, px: 1
      }}>
        <Card>
          <CardHeader title={"Adres"}/>
          <CardContent>
            <Grid2 container>
              <Grid2 md={6}>
                <div>
                  <span className="title">Straat:</span> {client.street}
                </div>
              </Grid2>
              <Grid2 md={6}>
                <div>
                  <span className="title">Postcode:</span> {client.zipCode}
                </div>
              </Grid2>
              <Grid2 md={6}>
                <div>
                  <span className="title">Stad:</span> {client.city}
                </div>
              </Grid2>
              <Grid2 md={6}>
                <div>
                  <span className="title">Land:</span> {client.country}
                </div>
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      </Box>

      <Box sx={{
        py: 1, px: 1
      }}>
        <Card>
          <CardHeader title={"Facturen"}/>
          <CardContent>
            <Grid2 container>
              <Grid2 md={12}>
                <div style={{height: 400, width: '100%'}}>
                  <InvoiceTable
                    key={invoices.length}
                    invoices={invoices}
                  />
                </div>
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      </Box>

    </Box>
  </>
}