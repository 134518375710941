import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {ProtectedRoute} from "./components/utils/ProtectedRoute";
import NotFound from "./pages/static/404";
import LoginPage from "./pages/user/LoginPage";
import ClientListPage from "./pages/clients/clientList.page";
import {theme} from "./config/theme";
import ClientView from "./pages/clients/clientView";
import ClientEditPage from "./pages/clients/clientEdit.page";
import InvoiceListPage from "./pages/invoice/invoiceList.page";
import InvoiceCreatePage from "./pages/invoice/invoiceCreate.page";
import Notifications from "./components/layout/notifications";
import InvoiceDetailPage from "./pages/invoice/detailPage/invoiceDetailPage";
import CompanyIndexPage from "./pages/company/index/CompanyIndexPage";
import CompanyEditPage from "./pages/company/edit/CompanyEditPage";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {useApplicationContext} from "./context/ApplicationContext";
import Sidebar from "./components/layout/sidebar";
import LogoutPage from "./pages/user/LogoutPage";
import {useAuth} from "./context/AuthContext";
import RegisterPage from "./pages/user/RegisterPage";
import {getFeatures} from "./service/feature.service";
import {AppRoutes, isRoutePrivate} from "./utils/AppRoutes";
import PasswordForgotPage from "./pages/user/PasswordForgotPage";


const drawerWidth = 256;

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://coolandwicked.nl/">
        Cool & Wicked
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function AppComponent() {
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const appContext = useApplicationContext();
  const {isLoggedIn} = useAuth();
  const navigator = useNavigate()
  const location = useLocation();
  const {token} = useAuth()
  const [fetchedFeatures, setFetchedFeatures] = useState<boolean>(false)

  const handleDrawerToggle = () => {
    appContext.setMobileOpen(!appContext.isMobileOpen);
  };

  useEffect(() => {
    if (!isLoggedIn() && isRoutePrivate(location.pathname)) {
      navigator(AppRoutes.USER.LOGIN, {replace: true})
      window.location.reload();
    }
    if (!fetchedFeatures && isLoggedIn()) {
      getFeatures(token).then((result) => {
        if (!result.error.hasError) {
          appContext.setFeatures(result.data)
        }
      });
      setFetchedFeatures(true)
    }
  }, [isLoggedIn, fetchedFeatures, location.pathname, navigator, token, appContext]);


  return <>
    <Box sx={{display: 'flex', minHeight: '100vh'}}>
      <CssBaseline/>
      <Box
        component="nav"
        sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
      >
        {isSmUp ? null : (
          <Sidebar
            PaperProps={{style: {width: drawerWidth}}}
            variant="temporary"
            open={appContext.isMobileOpen}
            onClose={handleDrawerToggle}
          />
        )}
        <Sidebar
          PaperProps={{style: {width: drawerWidth}}}
          sx={{display: {sm: 'block', xs: 'none'}}}
        />
      </Box>
      <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
        <Box component="main" sx={{flex: 1, bgcolor: '#eaeff1'}}>
          <Routes>
            <Route index element={<CompanyIndexPage/>}/>
            <Route element={<ProtectedRoute/>}>
              <Route path="/" element={<CompanyIndexPage/>}/>

              <Route path={"company"}>
                <Route index element={<CompanyIndexPage/>}/>
                <Route path={"edit"} element={<CompanyEditPage/>}/>
              </Route>

              <Route path={"client"}>
                <Route index element={<ClientListPage/>}/>
                <Route path={"new"} element={<ClientEditPage/>}/>
                <Route path={":id"} element={<ClientView/>}/>
                <Route path={":id/edit"} element={<ClientEditPage/>}/>
              </Route>
              <Route path={"invoice"}>
                <Route index element={<InvoiceListPage/>}/>
                <Route path={"new"} element={<InvoiceCreatePage/>}/>
                <Route path={":id"} element={<InvoiceDetailPage/>}/>
              </Route>

            </Route>
            <Route path={"user"}>
              <Route path={"login"} element={<LoginPage/>}/>
              <Route path={"register"} element={<RegisterPage/>}/>
              <Route path={"logout"} element={<LogoutPage/>}/>
              <Route path={"forgot-password"} element={<PasswordForgotPage/>}/>
            </Route>


            <Route path="*" element={<NotFound/>}/>
          </Routes>

          <Notifications/>
        </Box>
        <Box component="footer" sx={{p: 2, bgcolor: '#eaeff1'}}>
          <Copyright/>
        </Box>
      </Box>
    </Box>
  </>
}