import {useAuth} from "../../context/AuthContext";
import React, {useEffect, useState} from "react";
import {getClients} from "../../service/client.service";
import {ClientDto} from "../../dto/client.dto";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
import {clientColumns} from "./ClientColumns";
import {Box, Button, ButtonGroup, Paper, Stack} from "@mui/material";
import ApiCallResults from "../../components/utils/ApiCallResult";
import {ApiError} from "../../service/apiUtils";
import Header from "../../components/layout/Header";
import {AppRoutes, getRouteWithParams} from "../../utils/AppRoutes";

const actionColumn: GridColDef[] = [
  {
    field: 'action', headerName: "Acties", width: 200,
    renderCell: (params) =>
      <div className={"cellAction"}>
        <ButtonGroup>
          <Button component={Link} to={getRouteWithParams(AppRoutes.CLIENT.VIEW, {id: params.row.id})}>
            Bekijk
          </Button>
          <Button component={Link}
                  to={`/client/${params.row.id}/edit`}
                  color="warning">
            Bewerk
          </Button>
        </ButtonGroup>
      </div>
  }
]

export default function ClientListPage() {
  const {token} = useAuth()
  const [data, setData] = useState<ClientDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ApiError>({hasError: false} as ApiError);

  useEffect(() => {
    async function getData() {
      if (loading) {
        const {data, error} = await getClients(token);
        setData(data)
        setError(error)
        setLoading(false)
      }
    }

    getData()
  }, [loading])

  return <>
    <Header
      title={"Klanten"}
    >
      <ButtonGroup>
        <Button
          size="small"
          color="inherit"
          component={Link} to={"/client/new"}>
          Klant aanmaken
        </Button>
      </ButtonGroup>
    </Header>
    <Box sx={{
      py: 1, px: 1
    }}>


      <ApiCallResults loading={loading} error={error}/>
      <Paper sx={{maxWidth: '100vw', margin: 'auto', overflow: 'hidden'}}>
        <div className="datatable">
          <div style={{height: 400, width: '100%'}}>
            <DataGrid
              className="datagrid"
              rows={data}
              columns={clientColumns.concat(actionColumn)}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    Geen klanten gevonden
                  </Stack>
                ),
              }}
            />
          </div>
        </div>
      </Paper>
    </Box>
  </>
}
