import {Alert, Snackbar} from "@mui/material";
import React from "react";
import {useLocation} from "react-router-dom";

export default function Notifications() {
  const location = useLocation()

  if (location === null || location.state === null) {
    return <></>
  }
  return <>
    {location.state.message && location.state.severity && <>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open>
        <Alert severity={location.state.severity}>{location.state.message}</Alert>
      </Snackbar>
    </>}
  </>
}