import ApiCallResults from "../../components/utils/ApiCallResult";
import {useAuth} from "../../context/AuthContext";
import React, {useEffect, useState} from "react";
import {ApiError} from "../../service/apiUtils";
import {InvoiceDto} from "../../dto/invoice.dto";
import {getInvoices} from "../../service/invoice.service";
import {Box, Button, ButtonGroup, Paper} from "@mui/material";
import {Link} from "react-router-dom";
import Header from "../../components/layout/Header";
import InvoiceTable from "../../components/invoice/InvoiceTable";

export default function InvoiceListPage() {
  const {token} = useAuth()
  const [data, setData] = useState<InvoiceDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ApiError>({hasError: false} as ApiError);

  useEffect(() => {
    async function getData() {
      if (loading) {
        const {data, error} = await getInvoices(token);
        setData(data)
        setError(error)
        setLoading(false)
      }
    }

    getData()
  }, [loading])

  if (loading) {
    return <ApiCallResults loading={loading} error={error}/>
  }

  return <>
    <Header
      title={"Facturen"}
    >
      <ButtonGroup aria-label="outlined primary button group">
        <Button component={Link}
                to={"/invoice/new"}
                color="inherit">
          Nieuwe factuur
        </Button>
      </ButtonGroup>
    </Header>

    <Box sx={{
      py: 1, px: 1
    }}>
      <ApiCallResults loading={loading} error={error}/>

      <Paper sx={{maxWidth: "100vw", margin: 'auto', overflow: 'hidden'}}>
        <div className="datatable">
          <div style={{height: 400, width: '100%'}}>
            <InvoiceTable invoices={data}/>
          </div>
        </div>
      </Paper>
    </Box>
  </>
}