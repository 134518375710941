import {CompanyDto} from "../../../dto/company.dto";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../context/AuthContext";
import {UserDto} from "../../../dto/user.dto";
import {ApiError} from "../../../service/apiUtils";
import {getUserSelf} from "../../../service/user.service";
import ApiCallResults from "../../../components/utils/ApiCallResult";
import {Button, ButtonGroup} from "@mui/material";
import {Link} from "react-router-dom";
import Header from "../../../components/layout/Header";
import {useApplicationContext} from "../../../context/ApplicationContext";
import CompanyEditGeneralSettings from "../components/CompanyEditGeneralSettings";
import CompanyEditEmailTemplates from "../components/CompanyEditEmailTemplates";
import {Features, isFeatureEnabled} from "../../../config/ApplicationFeatures";


export default function CompanyEditPage() {
  const {tabIndex, setTabIndex, features} = useApplicationContext()
  const {token} = useAuth();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserDto | null>(null);
  const [error, setError] = useState<ApiError>({hasError: false} as ApiError);

  useEffect(() => {
    setTabIndex(0) // todo: IA-33 reset to 0 automatically in the applicationContext
    async function loadData() {
      const {data, error} = await getUserSelf(token);
      setUser(data)
      setError(error)
      setLoading(false)
    }

    loadData()
  }, [loading, token])

  const getTabs = () => {
    const tabs = ['Algemene gegevens']
    if (isFeatureEnabled(features!!, Features.COMPANY_EMAIL_CONFIGURATION)) {
      tabs.push('Email templates')
    }
    return tabs
  }

  if (loading) return <>
    <ApiCallResults loading={loading} error={error}/>
  </>

  return <>
    <Header title={`Mijn bedrijf bewerken - ${(user?.company as CompanyDto)?.name}`}
            tabs={getTabs()}>
      <ButtonGroup aria-label="outlined primary button group">
        <Button size="small"
                color="inherit"
                component={Link} to={-1 as any}>
          Back
        </Button>
      </ButtonGroup>
    </Header>
    <ApiCallResults loading={loading} error={error}/>


    {tabIndex === 0 && <CompanyEditGeneralSettings user={user!!}/>}
    {tabIndex === 1 && isFeatureEnabled(features!!, Features.COMPANY_EMAIL_CONFIGURATION) &&
      <CompanyEditEmailTemplates/>}


  </>

}