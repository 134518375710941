import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
  downloadInvoice,
  getInvoice,
  getInvoiceDownloadUrl,
  invoiceUpdatePaymentStatus
} from "../../../service/invoice.service";
import {useAuth} from "../../../context/AuthContext";
import {Document, Page, pdfjs} from "react-pdf";
import {Button, ButtonGroup, Paper} from "@mui/material";
import {InvoiceDto} from "../../../dto/invoice.dto";
import Header from "../../../components/layout/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import NoPdfSelectedMessage from "../pdf/noPdfSelectedMessage";
import Box from "@mui/material/Box";
import InvoiceDetailsComponent from "../components/invoiceDetailsComponent";
import InvoiceActionsComponent from "../components/invoiceActionsComponent";

export default function InvoiceDetailPage() {
  //todo: figure out if this can be removed?
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const param = useParams()
  const {token} = useAuth()
  const [loading, setLoading] = useState<boolean>(true)
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [url, setUrl] = useState<string | null>(null)
  const [invoiceData, setInvoiceData] = useState<InvoiceDto | null>(null)

  useEffect(() => {
    async function loadData() {
      const [invoiceData, invoiceUrl] = await Promise.all([
        getInvoice(token, param.id as string),
        getInvoiceDownloadUrl(token, param.id as string)
      ])
      setUrl(invoiceUrl.data)
      console.log("Invoice data", invoiceData.data)
      setInvoiceData(invoiceData.data)
      setLoading(false)
    }

    loadData()
  }, [])

  const invoiceDownloadFunc = () => async () => {
    await downloadInvoice(token, invoiceData!!.id)
  }
  const updateInvoicePaymentStatus = () => async () => {
    setLoading(true)
    const result = await invoiceUpdatePaymentStatus(token, invoiceData!!.id)
    if (result.error.hasError) {
      // setError(result.error)
    } else {
      invoiceData!!.isPaid = !invoiceData!!.isPaid;
    }
    setLoading(false)
  }

  function onDocumentLoadSuccess({numPages}: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function onLoadError(error: Error) {
    console.log('some error while loading the pdf', error)
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  if (loading || !invoiceData) {
    return <div>Loading...</div>
  }

  return <>
    <Header
      title={`Factuur voor ${invoiceData.client.name}`}
    >
      <ButtonGroup aria-label="outlined primary button group">
        <Button size="small"
                color="inherit"
                component={Link}
                to={-1 as any}>
          Terug
        </Button>
      </ButtonGroup>
    </Header>
    <Box sx={{
      py: 2, px: 2
    }}>
      <Grid2 container sx={{py: 1, px: 1}}>
        <Grid2 xs={12} md={6}>
          <Paper sx={{py: 1, px: 1}}>
            {numPages !== undefined && numPages > 1 && <>
              <div className="controls">
                <p>
                  Pagina {pageNumber || (numPages ? 1 : "--")} van {numPages || "--"}
                </p>
                <Button type="button" variant="contained" disabled={pageNumber <= 1} onClick={previousPage}>
                  Vorige
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  disabled={numPages ? pageNumber >= numPages : true}
                  onClick={nextPage}
                >
                  Volgende
                </Button>
              </div>
            </>}
            <Document className="preview"
                      file={url}
                      renderMode={"svg"}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={onLoadError}
                      noData={<NoPdfSelectedMessage/>}
            >
              <Page pageNumber={pageNumber}/>
            </Document>
          </Paper>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Paper sx={{py: 1, px: 1}}>
            <Box>
              <InvoiceDetailsComponent invoice={invoiceData}/>
            </Box>
          </Paper>
          <br/>
          <Paper sx={{py: 1, px: 1}}>
            <Box>
              <InvoiceActionsComponent
                invoice={invoiceData}
                downloadInvoice={invoiceDownloadFunc}
                updateInvoicePaymentStatus={updateInvoicePaymentStatus}
              />
            </Box>
          </Paper>
        </Grid2>


      </Grid2>
    </Box>
  </>
}