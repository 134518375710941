import * as yup from "yup"


export const userRegisterSchema = yup.object({
  companyName: yup.string()
    .required('Bedrijfsnaam is verplicht'),
  email: yup.string()
    .email('Voer een geldig email adres in')
    .required('Emailadres is verplicht'),
  password: yup.string()
    .required('Wachtwoord is verplicht')
    .matches(
      /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
      , `Wachtwoord moet minimaal 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal karakter bevatten`),
  firstname: yup.string()
    .required('Voornaam is verplicht'),
  lastname: yup.string()
    .required('Achternaam is verplicht'),
})


export const CreateAccountSchema = yup.object({
  user: userRegisterSchema
})

export interface CreateAccountDto extends yup.InferType<typeof CreateAccountSchema> {

}