import {GridColDef} from "@mui/x-data-grid";
import {Button, ButtonGroup} from "@mui/material";
import {Link} from "react-router-dom";
import {formatDateString} from "../../config/date-util";

export function invoiceListHeaderColumns(
  updateInvoicePaymentStatus: Function,
  downloadInvoiceAction: Function
): GridColDef[] {
  return [
    {
      field: 'clientName', headerName: 'Klant', width: 200,
      valueGetter: params => params.row.client.name
    },
    {
      field: 'assignment', headerName: 'Opdracht', width: 350,
    },
    {field: 'invoiceNumber', headerName: 'Factuur nr', width: 100},
    {
      field: 'createdAt', headerName: 'Aangemaakt op', width: 150,
      valueGetter: params => formatDateString(params.row.createdAt)
    },
    {
      field: 'action', headerName: "Acties", width: 400,
      renderCell: (params) => {


        return <div className={"cellAction"}>
          <ButtonGroup>
            <Button onClick={downloadInvoiceAction(params.row.id)}
                    color="primary"
                    to={""}
                    component={Link}
            >
              Download
            </Button>
            <Button component={Link}
                    to={`/invoice/${params.row.id}`}
                    color="success">
              Bekijken
            </Button>
            {params.row.isPaid && <>
              <Button component={Link}
                      to={``}
                      onClick={updateInvoicePaymentStatus(params.row.id)}
                      color="success">
                Betaald
              </Button>
            </>}
            {!params.row.isPaid && <>
              <Button component={Link}
                      to={``}
                      onClick={updateInvoicePaymentStatus(params.row.id)}
                      color="error">
                Niet betaald
              </Button>
            </>}
          </ButtonGroup>
        </div>
      }
    }
  ]
}