import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';


import {CreateInvoiceDto, InvoiceDto, invoiceSchema} from "../../dto/invoice.dto";
import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {ApiError, GetResult, PostResult} from "../../service/apiUtils";
import {getClients} from "../../service/client.service";
import {ClientDto} from "../../dto/client.dto";
import ApiCallResults from "../../components/utils/ApiCallResult";
import {Box, Button, ButtonGroup} from "@mui/material";
import InvoiceClientContainer from "./invoiceClientContainer";
import InvoiceDetailsContainer from "./InvoiceDetailsContainer";
import {NextInvoiceNumberDto} from "../../dto/NextInvoiceNumber.dto";
import {createInvoice, getNextInvoiceNumber} from "../../service/invoice.service";
import {LocalDate, LocalDateTime, OffsetDateTime, ZoneOffset} from "@js-joda/core";
import InvoicePreviewContainer from "./pdf/invoicePreviewContainer";
import Header from "../../components/layout/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import InvoiceLinesTable from "./components/invoiceLinesTable";
import {AppRoutes, getRouteWithParams} from "../../utils/AppRoutes";
import InvoiceCompanySetupDialog from "./components/dialog/InvoiceCompanySetupDialog";
import {getUserSelf} from "../../service/user.service";
import {UserDto} from "../../dto/user.dto";
import {CompanyDto} from "../../dto/company.dto";
import {useApplicationContext} from "../../context/ApplicationContext";


export default function InvoiceCreatePage() {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const param = useParams()
  const {token} = useAuth()
  const appContext = useApplicationContext()
  const navigator = useNavigate()
  const [error, setError] = useState({} as ApiError)
  const methods = useForm<CreateInvoiceDto>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      client: '',
      vatRate: 21,
      lines: [
        {
          'type': 'Uur',
          amount: 0,
          price: 0,
          description: ""
        }
      ]
    },
    resolver: yupResolver(invoiceSchema)
  })

  const [mode, setMode] = useState<string | null>(null)
  const [clients, setClients] = useState<ClientDto[] | null>(null)
  const [user, setUser] = useState<UserDto | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  function handleClientResponse(clientResponse: GetResult<ClientDto[]>) {
    if (clientResponse.error.hasError) {
      setError(clientResponse.error)
    } else {
      setClients(clientResponse.data)
    }
  }

  function handleNextInvoiceNumberResponse(numberResponse: GetResult<NextInvoiceNumberDto>) {
    if (numberResponse.error.hasError) {
      setError(numberResponse.error)
    } else {
      methods.setValue("invoiceNumber",
        numberResponse.data.nextNumber,
        {
          shouldValidate: true,
          shouldDirty: true
        })
    }
  }

  function handleUserResponse({error, data}: GetResult<UserDto>) {
    if (error.hasError) {
      setError(error)
    } else {
      setUser(data)
    }
  }

  useEffect(() => {
    setMode(param.id !== undefined ? "edit" : "create")
    if (param.id !== null) {
      // load data
    }
    const loadData = async () => {
      const [userResponse, clientResponse, invoiceNumberResponse] = await Promise.all([
        getUserSelf(token),
        getClients(token),
        getNextInvoiceNumber(token)
      ])
      handleUserResponse(userResponse)
      handleClientResponse(clientResponse)
      handleNextInvoiceNumberResponse(invoiceNumberResponse)
      setLoading(false)
    }
    loadData()
  }, [param.id, token])

  const saveForm: SubmitHandler<CreateInvoiceDto> = async data => {
    setLoading(true)
    console.log("submit?", methods.formState)
    if (methods.formState.isValid) {
      const date = OffsetDateTime.of((data.invoiceDate as LocalDateTime), ZoneOffset.UTC)
      console.log("Submitting invoice form", data)
      console.log("date", date)
      console.log("date", (data.invoiceDate as LocalDate))
      data.invoiceDate = date
      let response: PostResult<InvoiceDto> = {} as PostResult<InvoiceDto>
      console.log("server response", response)

      if (mode === "edit") {
        // todo
      } else if (mode === "create") {
        response = await createInvoice(token, data)
      } else {
        console.error("Error state, unknown mode", mode)
      }

      if (response.error.hasError) {
        setError(response.error)
      } else {
        navigator(getRouteWithParams(AppRoutes.INVOICE.VIEW, {id: response.data.id}), {
          state: {
            message: (mode === "edit" ? 'Factuur succesvol bewerkt' : 'Factuur succesvol aangemaakt'),
            severity: 'success'
          }
        })
      }
    }
    setLoading(false)
  }

  if (mode === null || loading) {
    return <>
      <ApiCallResults loading={loading} error={{} as ApiError}/>
    </>
  }

  function isValid() {
    return !methods.formState.isValid;
  }

  function isCompanySetupDone() {
    if (user === null) {
      console.log("User is null")
      return false
    }
    const company = user.company as CompanyDto
    return company.name !== null
      && company.city !== null && company.city !== ""
      && company.country !== null && company.country !== ""
      && company.zipCode !== null && company.zipCode !== ""
      && company.street !== null && company.street !== ""
      && company.settings.vatNumber !== null && company.settings.vatNumber !== ""
      && company.settings.iban !== null && company.settings.iban !== ""
      && company.settings.kvkNumber !== null && company.settings.kvkNumber !== ""
  }

  return <>
    { ((clients === null || clients.length === 0) || !isCompanySetupDone()) &&
      <>
        <InvoiceCompanySetupDialog
          noClients={clients === null || clients.length === 0}
          companySetupDone={!isCompanySetupDone()}
        />
      </>
    }
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(saveForm)}>

        <Header title={`Factuur aanmaken`}>
          <ButtonGroup aria-label="outlined primary button group">
            <Button size="small"
                    color="inherit"
                    component={Link} to={-1 as any}>
              Back
            </Button>
            <Button type="submit"
                    color="inherit"
                    size="small"
                    disabled={isValid()}
            >
              Opslaan
            </Button>
          </ButtonGroup>
        </Header>

        <ApiCallResults loading={loading} error={error}/>

        <Box sx={{py: 1, px: 1}}>
          <Grid2 container>
            <Grid2 xs={12} md={6}>
              <InvoiceClientContainer
                methods={methods}
                clients={clients}
              />
              <InvoiceDetailsContainer/>
              <InvoiceLinesTable
                methods={methods}/>
            </Grid2>

            <Grid2 xs={12} md={6}>
              <InvoicePreviewContainer/>
            </Grid2>
          </Grid2>
        </Box>

      </form>
    </FormProvider>
  </>
}