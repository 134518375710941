import {FieldArrayWithId, UseFieldArrayRemove, useFormContext} from "react-hook-form";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React, {useEffect} from "react";
import FormFieldComp from "../../../components/form/FormFieldComponent";
import FormFieldDropdownComponent from "../../../components/form/FormFieldDropdownComponent";
import {BasicFormField, FormSelectField} from "../../../components/utils/BasicFormField";
import {CreateInvoiceLineDto, InvoiceLineType} from "../../../dto/invoiceLine.dto";
import {Button} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

const invoiceLineFields: BasicFormField<CreateInvoiceLineDto>[] = [
  {
    name: 'description',
    inputType: 'textarea',
    required: true,
    label: 'Omschrijving'
  },
  {
    name: 'price',
    inputType: 'text',
    required: true,
    label: ' '
  },
  {
    name: 'amount',
    inputType: 'numeric',
    required: true,
    label: ' '
  }
]

const invoiceDropdown: FormSelectField<CreateInvoiceLineDto> [] = [
  {
    name: 'type',
    required: true,
    label: '',
    options: InvoiceLineType.map(item => {
      return {
        value: item,
        label: item
      }
    })
  }
]

export default function InvoiceLineTableItem(props: {
  field: FieldArrayWithId,
  index: number,
  remove: UseFieldArrayRemove
}) {
  const methods = useFormContext<any>()
  const {index} = props
  const isHourType = (field: BasicFormField<CreateInvoiceLineDto>) =>
    field.name !== 'price' || methods.getValues(`lines[${props.index}].type`) !== 'Uur'

  const removeInvoiceLine = () => {
    console.log("Remove invoice line", props.index);
    props.remove(props.index)
  }

  return <>
    <TableRow key={`invoiceLine-${index}`}
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
    >
      {invoiceLineFields
        .map(field =>
          <TableCell key={`table-cell-${field.name}`}>
            <FormFieldComp key={field.name}
                           disabled={field.name === 'price' && !isHourType(field)}
                           field={field}
                           style={'standard'}
                           name={`lines[${props.index}].${field.name}`}
            />
          </TableCell>
        )}
      {invoiceDropdown.map((field, index) => (
        <TableCell key={index}>
          <FormFieldDropdownComponent
            style={'standard'}
            field={field}
            grid={12}
            name={`lines[${props.index}].${field.name}`}
            key={field.name}
          />
        </TableCell>
      ))}
      <TableCell>
        <Button type="button"
                color="error"
                onClick={removeInvoiceLine}>
          <DeleteIcon fontSize={"small"}/>
        </Button>
      </TableCell>
    </TableRow>
  </>
}