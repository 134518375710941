import {Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import FormFieldComp from "../../../components/form/FormFieldComponent";
import CompanyUploadLogoComponent from "../../../components/company/upload-logo-component";
import React, {useEffect, useState} from "react";
import {BasicFormField} from "../../../components/utils/BasicFormField";
import {CompanyDto, companySettingsSchema, UpdateCompanySettingsDto} from "../../../dto/company.dto";
import {UserDto} from "../../../dto/user.dto";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {ApiError, PostResult} from "../../../service/apiUtils";
import {updateCompanySettings} from "../../../service/company.service";
import {useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useAuth} from "../../../context/AuthContext";
import ApiCallResults from "../../../components/utils/ApiCallResult";
import {AppRoutes} from "../../../utils/AppRoutes";


interface Props {
  user: UserDto
}

export default function CompanyEditGeneralSettings(props: Props) {
  const navigator = useNavigate()
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ApiError>({hasError: false} as ApiError);
  const {token} = useAuth();


  const methods = useForm<UpdateCompanySettingsDto>({
    resolver: yupResolver(companySettingsSchema),
    mode: 'all'
  });

  useEffect(() => {
    async function getData() {
      if (loading) {
        setError(error)
        if (!error.hasError) {
          const company = props.user.company as CompanyDto;
          methods.reset({
            name: company.name,
            color: company.settings.color,
            invoiceDueDays: company.settings.invoiceDueDays,
            iban: company.settings.iban,
            vatNumber: company.settings.vatNumber,
            kvkNumber: company.settings.kvkNumber,
            country: company.country,
            city: company.city,
            street: company.street,
            zipCode: company.zipCode,
          })
        }
        setLoading(false)
      }
    }

    getData()
  }, [loading, token])
  const submitData: SubmitHandler<UpdateCompanySettingsDto> = async data => {
    setLoading(true)
    console.log("Update company settings", data)
    const response: PostResult<CompanyDto> = await updateCompanySettings(token, (props.user.company as CompanyDto)?.id, data)
    if (response.error.hasError) {
      setError(response.error)
    } else {
      navigator(AppRoutes.HOME, {
        state: {
          message: "Bedrijfsinstellingen zijn succesvol opgeslagen",
          severity: 'success'
        }
      })
    }
    setLoading(false)
  }

  return <>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitData)}>
        <ApiCallResults loading={loading} error={error}/>
        <Box sx={{
          py: 1, px: 1
        }}>
          <Card>
            <CardHeader title={"Bedrijfsinstellingen"}/>
            <CardContent>
              <Grid2 container className="details">
                {companySettingsFields.map((field, index) =>
                  <Grid2 md={6} key={index}>
                    <FormFieldComp key={field.name}
                                   field={field}
                    />
                  </Grid2>)}
              </Grid2>
            </CardContent>

          </Card>
        </Box>

        <CompanyUploadLogoComponent
          user={props.user}
        />

        <Box sx={{
          py: 1, px: 1
        }}>
          <Card>
            <CardHeader title={"Adres"}/>
            <CardContent>
              <Grid2 container className="details">
                {companyAddressFields.map((field, index) =>
                  <Grid2 md={6} key={index}>
                    <FormFieldComp key={field.name}
                                   field={field}
                    />
                  </Grid2>
                )}
              </Grid2>
            </CardContent>
            <CardActions disableSpacing>
              <ButtonGroup>
                <Button type="submit"
                        color="success"
                        size="small">
                  Opslaan
                </Button>
              </ButtonGroup>
            </CardActions>
          </Card>
        </Box>
      </form>
    </FormProvider>
  </>
}


const companySettingsFields: BasicFormField<UpdateCompanySettingsDto>[] = [
  {
    name: 'name',
    inputType: 'text',
    required: true,
    label: 'Bedrijfsnaam',
  },
  {
    name: 'color',
    inputType: 'text',
    required: true,
    label: 'Kleur'
  },
  {
    name: 'invoiceDueDays',
    inputType: 'numeric',
    required: true,
    label: 'Factuur vervaltermijn (dagen)',
  },
  {
    name: 'iban',
    inputType: 'text',
    required: true,
    label: 'IBAN',
  },
  {
    name: 'vatNumber',
    inputType: 'text',
    required: true,
    label: 'BTW nummer',
  },
  {
    name: 'kvkNumber',
    inputType: 'text',
    required: true,
    label: 'KVK nummer',
  }
]

const companyAddressFields: BasicFormField<UpdateCompanySettingsDto>[] = [
  {
    name: "street",
    inputType: "text",
    required: true,
    label: "straatnaam en nummer"
  },
  {
    name: "zipCode",
    inputType: "text",
    required: true,
    label: "Postcode"
  },
  {
    name: "city",
    inputType: "text",
    required: true,
    label: "Stad"
  },
  {
    name: "country",
    inputType: "text",
    required: true,
    label: "Land"
  }
]