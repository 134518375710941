import {Alert, Backdrop, CircularProgress, Snackbar} from "@mui/material";
import {ApiError} from "../../service/apiUtils";

export default function ApiCallResults(props: {
  loading: boolean,
  error?: ApiError
}) {
  function getErrorMessage() {
    if (props.error?.details?.status) {
      return `${props.error?.details?.title} - ${props.error?.details?.detail}`
    } else {
      return `Er ging iets mis - ${props.error?.message}`
    }
  }

  return <>
    <Backdrop
      sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
      open={props.loading}>
      <CircularProgress color="inherit"/>
    </Backdrop>
    <Snackbar open={!props.loading && props.error?.hasError}>
      <Alert severity="error">{getErrorMessage()}</Alert>
    </Snackbar>
  </>
}