import {Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";

interface ConfironmationDialogProps {
  title: string,
  message: string,
  onConfirm: () => void,
  onCancel: () => void,
  isOpen: boolean
}

export default function ConfirmationDialog(props: ConfironmationDialogProps) {
  const {title, message, onConfirm, onCancel, isOpen} = props
  return <>
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <ButtonGroup>

          <Button
            onClick={onCancel}
            color={"warning"}
          >
            Annuleren
          </Button>
          <Button
            onClick={onConfirm}
            color={"success"}
          >
            Doorgaan
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  </>
}