import ActionDialog from "../../../../components/utils/dialogs/ActionDialog";
import {Button, ButtonGroup} from "@mui/material";
import {AppRoutes} from "../../../../utils/AppRoutes";
import React from "react";
import {useNavigate} from "react-router-dom";

export default function InvoiceCompanySetupDialog(props: {
  noClients: boolean
  companySetupDone: boolean
}) {
  const navigator = useNavigate()

  const title = props.noClients && props.companySetupDone ? "Geen klanten gevonden" : "Gegevens missen"

  function getMessage(): React.ReactNode {
    const errors = []
    if (props.noClients) {
      errors.push("Er zijn nog geen klanten aangemaakt, maak je eerste klant aan.")
    }
    if (props.companySetupDone) {
      errors.push("De bedrijfsgegevens zijn nog niet ingevuld. Vul deze eerst in.")
    }
    return <ul>
      {errors.map((error, index) => <li key={index}>{error}</li>)}
    </ul>
  }

  return <>
    <ActionDialog
      isOpen={true}
      title={title}
      message={getMessage()}
    >
      <ButtonGroup>
        {props.companySetupDone && <>
          <Button
            onClick={() => navigator(AppRoutes.COMPANY.EDIT)}
            color={"primary"}
          >
            Bedrijfsgegevens aanvullen
          </Button>
        </>}
        {props.noClients && <>
          <Button
            onClick={() => navigator(AppRoutes.CLIENT.CREATE)}
            color={"primary"}
          >
            Naar client aanmaken
          </Button>
        </>}
      </ButtonGroup>
    </ActionDialog>
  </>
}