import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useApplicationContext} from "../../context/ApplicationContext";

interface HeaderProps {
  title: string,
  tabs?: string[],
  children?: string | JSX.Element | JSX.Element[]
}

export default function Header(props: HeaderProps) {
  const appContext = useApplicationContext();

  const onDrawerToggleHandler = () => {
    appContext.setMobileOpen(!appContext.isMobileOpen)
  }
  const changeTabs = (event: React.SyntheticEvent, newValue: number) => {
    appContext.setTabIndex(newValue)
  }

  return (
    <React.Fragment>
      <div className="page-header">

        <AppBar color="primary" position="sticky" elevation={0}>
          <Toolbar sx={{display: {sm: 'none', xs: 'block'}}}>
            <Grid container spacing={1} alignItems="center">
              <Grid sx={{display: {sm: 'none', xs: 'block'}}} item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggleHandler}
                  edge="start"
                >
                  <MenuIcon/>
                </IconButton>
              </Grid>
              <Grid item xs/>
            </Grid>
          </Toolbar>
        </AppBar>

        <AppBar
          component="div"
          color="primary"
          position="static"
          elevation={0}
          sx={{
            zIndex: 0,
            py: 1
          }}
        >
          <Toolbar>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs>
                <Typography color="inherit" variant="h5" component="h1">
                  {props.title}
                </Typography>
              </Grid>
              {(props.children !== undefined &&
                <Grid item>
                  {props.children}
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
        <AppBar component="div" position="static" elevation={0} sx={{zIndex: 0}}>
          <Tabs value={appContext.tabIndex} textColor="inherit" onChange={changeTabs}>
            {props.tabs !== undefined && props.tabs.map((tab, index) => {
              return <Tab key={index} label={tab}/>
            })}
          </Tabs>
        </AppBar>

      </div>
    </React.Fragment>
  );
}
