import {AuthToken} from "../config/axios";
import {apiGet, apiPut} from "./apiUtils";
import {EmailTemplateDto, EmailTemplateUpdateDto} from "../dto/EmailTemplate.dto";

export const getEmailTemplates = async (token: AuthToken) =>
  await apiGet<EmailTemplateDto[]>(`email-template`, token)

export const getEmailTemplateVariables = async (token: AuthToken) =>
  await apiGet<Map<string, string>>(`email-template/variables`, token)

export const updateTemplate = async (token: AuthToken, template: EmailTemplateUpdateDto) =>
  await apiPut<void>(`email-template`, token, template)