import {InvoiceDto} from "../../../dto/invoice.dto";
import {EmailTemplateType} from "../../../dto/EmailTemplate.dto";
import {formatDateTimeString} from "../../../config/date-util";
import React from "react";

export default function InvoicePreviousActions(props: {
  invoice: InvoiceDto
}) {
  const {invoice} = props
  const mails = invoice.mails.filter(mail => mail.sendAt !== null)
  return <>
    <h3>Uitgevoerde acties</h3>

    {mails.length > 0 && <>
      <div>
        Mails verstuurd:
        <ul>
          {mails.map(email =>
            <li key={email.id}>{EmailTemplateType[email.emailType]} - verzonden
              op {formatDateTimeString(email.sendAt as string)}</li>
          )}
        </ul>
      </div>
    </>}
    {(!invoice.mails || invoice.mails.length === 0) && <>
      <span>
        Geen mails verstuurd {invoice.client.email === "" ? " (geen klant email adres bekend)" : ""}
      </span>
    </>}
  </>
}