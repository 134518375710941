import {Box, Card, CardContent, CardHeader, TextField} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React, {useState} from "react";
import {UserDto} from "../../dto/user.dto";
import {useAuth} from "../../context/AuthContext";
import {getCompanyLogoUploadUrl, updateCompanyLogo} from "../../service/company.service";
import {ApiError} from "../../service/apiUtils";
import axios from "axios";
import {getLogo} from "../utils/logoUtils";


export default function CompanyUploadLogoComponent({user}: { user: UserDto | null }) {
  const {token} = useAuth()
  const [file, setFile] = useState<File | null>(null);
  const [logo, setLogo] = useState<string | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const changeCompanyLogoUrlAfterUpload = async (file: File) => {
    const uploadLogo = await updateCompanyLogo(token, {
      filename: file.name
    })
    console.log("Upload logo: ", uploadLogo)
  }

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files && event.target.files[0];
    setFile(uploadedFile || null);
    console.log("File: ", uploadedFile)
    if (uploadedFile === null) {
      console.error("No file selected")
    } else {
      const reader = new FileReader();
      reader.readAsBinaryString(uploadedFile);
      reader.onload = function () {
        setLogo(btoa(reader.result as string))
      };
      const uploadUrl = await getCompanyLogoUploadUrl(token, {
        filename: uploadedFile.name
      })
      setError(uploadUrl.error)
      if (!uploadUrl.error.hasError) {
        console.log("Upload url: ", uploadUrl)
        const response = await axios.create().put(uploadUrl.data, uploadedFile)
        console.log("Response: ", response)
        if (response.status === 200) {
          await changeCompanyLogoUrlAfterUpload(uploadedFile)
        } else {
          //todo: error while uploading image message
        }
      } else {
        //todo: handle error message
      }
    }
  };

  return <>
    <Box sx={{
      py: 1, px: 1
    }}>
      <Card>
        <CardHeader title={"Bedrijfslogo"}/>
        <CardContent>
          <Grid2 md={6}>
            <div>
              <img src={logo ? `data:image/png;base64,${logo}` : getLogo(user)}
                   alt="Logo"
                   width={200}
              />
            </div>
          </Grid2>
          <Grid2 md={6}>
            <Box>
              <TextField
                variant="outlined"
                type="file"
                onChange={handleUpload}
                inputProps={{accept: 'image/*'}}
              />
            </Box>
          </Grid2>
        </CardContent>
      </Card>
    </Box>

  </>
}