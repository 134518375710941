import {CompanyDto} from "../../../dto/company.dto";
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@mui/material";
import React from "react";
import {UserDto} from "../../../dto/user.dto";
import {Link} from "react-router-dom";
import {getLogo} from "../../../components/utils/logoUtils";

export default function CompanyInformation(
  {user}: {
    user: UserDto | null
  }) {

  const companyInfoRows = [
    {name: 'Naam', value: (user?.company as CompanyDto)?.name},
    {name: 'BTW nummer', value: (user?.company as CompanyDto)?.settings.vatNumber},
    {name: 'IBAN', value: (user?.company as CompanyDto)?.settings.iban},
    {name: 'KVK nummer', value: (user?.company as CompanyDto)?.settings.kvkNumber},
    {name: 'Factuur betaaltermijn', value: (user?.company as CompanyDto)?.settings.invoiceDueDays},
  ]

  const companyAddressRows = [
    {name: 'Straat', value: (user?.company as CompanyDto)?.street},
    {name: 'Postcode', value: (user?.company as CompanyDto)?.zipCode},
    {name: 'Stad', value: (user?.company as CompanyDto)?.city},
    {name: 'Land', value: (user?.company as CompanyDto)?.country},
  ]

  return <Card>
    <CardHeader
      title={`Bedrijfsgegevens`}
    />
    <CardContent>
      <div>
        <img src={getLogo(user)} alt="Logo"
             style={{
               maxWidth: 250,
             }}
        />
        <br/><br/>
        <TableContainer>
          <Table sx={{width: "100%"}} aria-label="simple table">
            <TableBody>
              {companyInfoRows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <h2>Adres</h2>
      <TableContainer>
        <Table sx={{width: "100%"}} aria-label="simple table">
          <TableBody>
            {companyAddressRows.map((row) => (
              <TableRow
                key={row.name}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>
                  {row.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </CardContent>
    <CardActions disableSpacing>
      <ButtonGroup>
        <Button component={Link}
                to={`/company/edit`}
                color="primary">
          Bewerken
        </Button>
      </ButtonGroup>
    </CardActions>
  </Card>
}