import * as yup from "yup";
import {isValidIban} from "../config/iban-util";

export interface CompanyDto {
  id: string,
  name: string,
  settings: CompanySettingsDto,
  country: string,
  city: string,
  street: string,
  zipCode: string,
  createdAt: Date
  updatedAt: Date | null,
}

export interface CompanySettingsDto {
  id: string | CompanyDto,
  color: string,
  invoiceDueDays: number,
  logo: string | null,
  iban: string,
  kvkNumber: string,
  vatNumber: string,
  createdAt: Date
  updatedAt: Date | null,
}

export interface UpdateCompanySettingsDto extends yup.InferType<typeof companySettingsSchema> {
}

export const companySettingsSchema = yup.object({
  name: yup.string().required(),
  color: yup.string().required(),
  invoiceDueDays: yup.number()
    .min(1, 'Moet minimaal 1 dag zijn')
    .max(365, 'Mag maximaal 365 dagen zijn')
    .required(),
  iban: yup.string()
    .test('is-iban',
      'IBAN is niet geldig',
      (value: string | null | undefined) => {
        return value ? isValidIban(value) : false;
      })
    .required(),
  vatNumber: yup.string()
    .matches(
      /^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$/,
      "BTW nummer is niet geldig"
    ).required(),
  kvkNumber: yup.string()
    .max(8, "KVK nummer moet 8 cijfers zijn")
    .min(8, "KVK nummer moet 8 cijfers zijn")
    .required(),
  country: yup.string().required(),
  city: yup.string().required(),
  street: yup.string().required(),
  zipCode: yup.string()
    .min(6, "Postcode moet 6 karakters zijn")
    .max(7, "Postcode moet 7 karakters zijn")
    .required()
})

export interface companyLogoUploadDto {
  filename: string
}